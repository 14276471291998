<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2"  v-if="!loading || metadata._id">
            <div>
              <v-chip v-if="stage === 3" small label color="success">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label color="warning">Pending for review</v-chip>
              <v-chip v-else small label color="info">Open</v-chip>
            </div>
            <div class="ml-2">
              <v-chip v-if="metadata.managerlist.indexOf(userid) > -1" small label color="caccounting" class="white--text">Manager</v-chip>
              <v-chip v-else small label color="">General</v-chip>  
            </div>
            <v-btn class="shadow-off ml-2" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
          </div>
          <div class="d-flex align-center mb-2" v-if="!loading || metadata._id">
            <div>
              
              <div class="d-flex align-center">
                <div class="subtitle-1 font-weight-bold mr-2">{{metadata.description}}</div>
              </div>
              <div>
                <span class="mr-2"><v-icon class="mr-1">mdi-calendar-range</v-icon> Period: </span>
                <span class="mr-1">{{$nova.formatDate(metadata.from)}}</span>
                <span class="mr-1">- {{$nova.formatDate(metadata.to)}}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center" v-if="checklist.length > 0">
              <div class="d-flex align-center mb-2 fbcPrimary--text mr-4">
                <span class="display-1">{{summary.count || 0}}</span>
                <span class="ml-1 line-1">Total Task<br/>Count</span>
              </div>
              <div class="d-flex align-center mb-2 success--text mx-4">
                <span class="display-1">{{summary.approved || 0}}</span>
                <span class="ml-1 line-1">Closed Task<br/>Count</span>
              </div>
              <div class="d-flex align-center mb-2 info--text mx-4">
                <span class="display-1">{{summary.pending || 0}}</span>
                <span class="ml-1 line-1">Pending Submission<br/>Count</span>
              </div>
              <div class="d-flex align-center mb-2 warning--text ml-4">
                <span class="display-1">{{summary.pendingapproval || 0}}</span>
                <span class="ml-1 line-1">Pending Approval<br/>Count</span>
              </div>
            </div>
          </div>
          <div v-if="loading && !metadata._id">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, article, article, article"
            ></v-skeleton-loader>
          </div>
          <div class="" v-else>
            <div class="flex-grow-1 d-block">
              <div class="px-0 pt-1" v-if="checklist.length > 0">
                <div class="d-flex align-center mb-2">
                  <!-- <div class="font-weight-bold mr-4 body-2">Filter: </div>
                  <v-checkbox label="Pending" class="mt-0 mr-4 info--text" dense hide-details color="info" v-model="stagefilter" multiple :value="0"></v-checkbox>       
                  <v-checkbox label="Pending Approval" class="mt-0 mr-4 warning--text" dense hide-details color="warning" v-model="stagefilter" multiple :value="2"></v-checkbox>       
                  <v-checkbox label="Closed" class="mt-0 mr-4 success--text" dense hide-details color="success" v-model="stagefilter" multiple :value="3"></v-checkbox>        -->
                    
                  
                  <v-btn small color="fbcPrimary" class="mr-1" @click="addEditForm()" v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"><v-icon class="mr-1">mdi-plus</v-icon>New</v-btn>
                  <bulkChecklistUpload :project="id" @reload="refreshData()" v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"/>
                  <div class="filterlists">
                    <div class="border-on d-flex shadow-on radius-all">
                        <div class="text-center px-4 py-2 hoverable">
                            <div class="grey--text text--darken-1 line-1">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</div>
                            <div class="font-weight-bold">
                                <v-menu offset-y v-if="filterListitems.locations?.length > 1" open-on-hover>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">
                                            {{ filterSelectedData.selectedlocation ?? 'All' }}
                                            <v-icon class="">mdi-chevron-down</v-icon>
                                        </div>
                                    </template>
                                    <div @click.stop=";" class="white pa-2">
                                      <lb-string v-model="locationSearch" hidedetails label="Search"></lb-string>
                                    </div>
                                    <perfect-scrollbar style="max-height: 300px">
                                        <v-list dense class="pa-0 border-left-default">
                                            <template v-for="(v, k) in filteredLocationList">
                                                <v-list-item @click="filterWithLocation(v)" :key="k">
                                                    <v-list-item-title>{{ v || 'NA' }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </perfect-scrollbar>
                                </v-menu>
                                <span v-else>NA</span>
                            </div>
                        </div>
                        <div class="text-center px-4 py-2 hoverable">
                            <div class="grey--text text--darken-1 line-1">User Name</div>
                            <div class="font-weight-bold">
                                <v-menu offset-y v-if="filterListitems.userlists?.length > 1" open-on-hover>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">
                                            {{ (userlist[filterSelectedData.selecteduser]?.name || filterSelectedData.selecteduser) ?? 'NA' }}
                                            <v-icon class="">mdi-chevron-down</v-icon>
                                        </div>
                                    </template>
                                    <div @click.stop=";" class="white pa-2">
                                      <lb-string v-model="userSearch" hidedetails label="Search"></lb-string>
                                    </div>
                                    <perfect-scrollbar style="max-height: 300px">
                                        <v-list dense class="pa-0 border-left-default">
                                            <template v-for="(v, k) in filteredUserList">
                                                <v-list-item @click="filterWithuser(v)" :key="k">
                                                    <v-list-item-title>{{ (userlist[v]?.name || 'All') ?? 'NA' }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </perfect-scrollbar>
                                </v-menu>
                                <span v-else>NA</span>
                            </div>
                        </div>
                        <div>
                            <lb-divider type="vertical"></lb-divider>
                        </div>
                        <div class="text-center py-2 px-1 hoverable">
                            <div>
                                <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click.stop="resetFilter()">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Reset filters</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>

                    
              
                  <v-spacer></v-spacer>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  color="" @click="opensendForReviewDialogbulk(multiselectdata)"><v-icon>mdi-file-upload</v-icon></v-btn>
                    </template>
                    <span>File Upload</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  color="caccounting"  @click="bulkunlinkBot(multiselectdata)"><v-icon>mdi-robot-happy</v-icon></v-btn>
                    </template>
                    <span>UnLink Bot</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small @click="bulkopenlinkBotDialog(multiselectdata)"><v-icon>mdi-robot-happy-outline</v-icon></v-btn>
                    </template>
                    <span>Link Bot</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  class="mr-1" @click="changeChecklistStatus(multiselectdata, 'approve')" color="success"><v-icon>mdi-check-circle</v-icon></v-btn>
                    </template>
                    <span>Bulk Approve</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  class="mr-1" @click="changeChecklistStatus(multiselectdata, 'reject')" color="error"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </template>
                    <span>Bulk Reject</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0 && metadata.managerlist.indexOf(userid) > -1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="info" icon small class=""
                        @click="bulkActionUserdilog('preparer')"><v-icon>mdi-account</v-icon></v-btn>
                    </template>
                    <span>Bulk Add/Remove Preparer</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0 && metadata.managerlist.indexOf(userid) > -1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="warning" icon small class=""
                        @click="bulkActionUserdilog('approver')"><v-icon>mdi-account</v-icon></v-btn>
                    </template>
                    <span>Bulk Add/Remove Approver</span>
                  </v-tooltip>
                  <Conversation
                    heading="Conversation"
                    v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')"
                    width="600"
                    :recurring="false"
                    :recurringText="''"
                    :closed="metadata.stage !== 0"
                    @markread="metadata.conversation_read = true"
                    :notify="!metadata.conversation_read"
                    :getapi="'/v2/financialclose/project/getconversation/'+metadata._id"
                    :sendapi="'/v2/financialclose/project/addconversation/'+metadata._id"
                    :readapi="'/v2/financialclose/project/markreadconversation/'+metadata._id"
                  />
                  <v-tooltip left content-class="tooltip-left" v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small icon class="" target="_blank" @click="downloadExcel()"><v-icon>mdi-file-excel-outline</v-icon></v-btn>
                    </template>
                    <span>Download Excel</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="" @click="downloadPPT()" v-bind="attrs" v-on="on" icon small>
                        <v-icon class="">mdi-presentation</v-icon>
                      </v-btn>
                    </template>
                    <span>Download PPT</span>
                  </v-tooltip>
                  <div class="line-1 ml-4">
                    <div class="d-flex align-center">
                      <v-avatar size="8" color="warning"></v-avatar>
                      <span class="ml-2">Approver</span>
                    </div>
                    <div class="d-flex align-center">
                      <v-avatar size="8" color="info"></v-avatar>
                      <span class="ml-2">Preparer</span>
                    </div>
                  </div>
                </div>
                <div>
                  <v-simple-table dense class="FC-Table">
                    <template v-slot:default>
                      <thead>
                        <th class="text-center" v-if="metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"></th>
                        <th class="text-left">Task Description</th>
                        <th class="text-center"></th>
                        <th class="text-left"> {{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}} 
                          <!-- <br/>
                          <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                          <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu</v-icon></v-btn>
                              </template>
                              <v-list dense class="border-left-default py-0" v-for="(v,index) in taskListLocation" :key="index">
                                <v-list-item class="py-0 px-0 my-0">
                                  <v-list-item-title class="pa-2">
                                    <div class="d-flex align-center">
                                      <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="3"></v-checkbox>
                                      <span class="success--text">{{ v }}</span>
                                    </div>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                              </v-menu> -->
                              </th>
                        <th class="text-center">Due Days</th>
                        <template v-for="(item ,index) in Tbheaders">
                           <th class="text-right" v-if="showTbHeaders" :key="index">{{item}}</th>
                        </template>
                        <th class="text-center" v-if="metadata.stage === 0" >Action Centre</th>
                        <th class="text-center d-flex ">Status<br/>
                          <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu</v-icon></v-btn>
                            </template>
                            <v-list dense class="border-left-default py-0">
                              <v-list-item class="py-0 px-0 my-0">
                                <v-list-item-title class="pa-2">
                                  <div class="d-flex align-center">
                                    <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="0"></v-checkbox>
                                    <v-icon class="info--text" left>mdi-timer-sand</v-icon>
                                    <span class="info--text">Pending Submission</span>
                                  </div>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item class="py-0 px-0 my-0">
                                <v-list-item-title class="pa-2">
                                  <div class="d-flex align-center">
                                    <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="2"></v-checkbox>
                                    <v-icon class="warning--text" left>mdi-timer-sand</v-icon>
                                    <span class="warning--text">Pending Approval</span>
                                  </div>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item class="py-0 px-0 my-0">
                                <v-list-item-title class="pa-2">
                                  <div class="d-flex align-center">
                                    <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="3"></v-checkbox>
                                    <v-icon class="success--text" left>mdi-check-circle</v-icon>
                                    <span class="success--text">Closed</span>
                                  </div>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-btn icon small @click="statushelpdialog = true"><v-icon>mdi-help-circle</v-icon></v-btn>
                        </th>
                      </thead>
                      <v-slide-y-transition class="" group tag="tbody">
                        <tr v-for="(v,k) in filterList" :key="k" class="">
                          <td v-if="v.type==='category'" colspan="10" class="py-2 font-weight-bold body-2 border-left-caccounting" :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.caccounting, 0.1)}`">
                            {{v.name}}
                          </td>
                          <template v-else>
                            <td class="text-center" v-if="metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                              <v-checkbox multiple v-model="multiselectdata" :value="v._id" hide-details dense class="pa-0 ma-0"></v-checkbox>
                            </td>
                            <td class="cursor-pointer hoverable" @click="viewTask(v)">
                                <!-- <v-checkbox hide-details dense class="ma-0 pa-0" v-model="multiselectdata"></v-checkbox> -->
                              <div class="max-width-div">
                                <span class="font-weight-bold">{{ v.ref }}</span>
                                <span class=""><v-icon>mdi-circle-small</v-icon></span>
                                {{v.description}}
                              </div>
                            </td>
                            <td class="text-center">
                                <v-avatar size="8" color="warning" v-if="(v.approver || []).indexOf(userid) > -1"></v-avatar>
                                <v-avatar size="8" color="info" v-else-if="(v.preparer || []).indexOf(userid) > -1"></v-avatar>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(v.preparer || []).length === 0 && (v.approver || []).length === 0">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="error">mdi-alert-circle</v-icon>
                                  </template>
                                  <span>No Users associated</span>
                                </v-tooltip>
                              </td>
                            <td class="cursor-pointer hoverable">
                              <div>
                                {{ v.locationName }} 
                              </div>
                            </td>
                            
                            <td class="text-center">
                              <div v-if="new Date(v.duedate || '')">
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage === 3">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="25" color="error white--text" v-bind="attrs" v-on="on" v-if="((new Date(v.duedate || '') - new Date(v.reviewed_on)))/(1000*24*60*60) < -1">
                                      {{Math.ceil((new Date(v.reviewed_on) - new Date(v.duedate || ''))/(1000*24*60*60))+1}}
                                    </v-avatar>
                                  </template>
                                  <span>Delay</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(new Date(v.duedate || '') - new Date()) >= -1">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="25" color="info white--text" v-bind="attrs" v-on="on">
                                      {{Math.ceil((new Date(v.duedate || '') - new Date())/(1000*24*60*60))+1}}
                                    </v-avatar>
                                  </template>
                                  <span>Days Remaining</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="(new Date(v.duedate || '') - new Date()) < -1">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="25" color="error white--text" v-bind="attrs" v-on="on">
                                      {{Math.ceil((new Date() - new Date(v.duedate || ''))/(1000*24*60*60))+1}}
                                    </v-avatar>
                                      </template>
                                  <span>Days Overdue</span>
                                </v-tooltip>
                              </div>                
                            </td>
                            <!-- <td class="cursor-pointer hoverable text-center" v-if="((v.__pbccount || {}).total || 0) > 0">
                              <div style="max-width:50px;margin:auto">
                                <v-tooltip bottom content-class="tooltip-bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div class="my-1 cursor-pointer" @click.stop="populatepbclinkdialogitems(v)" v-bind="attrs" v-on="on">
                                      {{ (((((v.__pbccount || {}).completed || 0)/((v.__pbccount || {}).total || 0)) || 0)*100).toFixed(0) }}%
                                      <v-progress-linear
                                        :height="10" color="success"
                                        :value="Math.max(((((v.__pbccount || {}).completed || 0)/((v.__pbccount || {}).total || 0)) || 0)*100,2)"
                                      >
                                      </v-progress-linear>
                                    </div>
                                  </template>
                                  <span>View Information Manager List</span>
                                </v-tooltip>
                              </div>
                            </td>
                            <td class="text-center" v-else></td> -->
                            <td class="cursor-pointer hoverable text-right" v-if="(v.__tbtotals || {}).total && showTbHeaders " @click.stop="populatetblinkdialogitems(v)">{{$nova.formatNumber(((v.__tbtotals || {}).total || 0).toFixed(0))}}</td>
                            <td class="text-right" v-else-if="showTbHeaders">0</td>
                            <td class="cursor-pointer hoverable text-right" v-if="Number((v.result || {}).value?.total_value) && showTbHeaders" @click.stop="populateschedulelinkdialogitems(v)">{{$nova.formatNumber((Number((v.result || {}).value?.total_value) || 0).toFixed(0))}}</td>
                            <td class="text-right" v-else-if="showTbHeaders">0</td>
                            <td class="text-right error--text" v-if="showTbHeaders">{{$nova.formatNumber((((v.__tbtotals || {}).total || 0) - (Number((v.result || {}).value?.total_value) || 0)).toFixed(0))}}</td>
                            <td class="text-center"  v-if="metadata.stage === 0 ">
                              <div class="d-flex align-center justify-center" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">

                                 <Conversation
                                  heading="Conversation"
                                  width="600"
                                  :recurring="false"
                                  :recurringText="''"
                                  :closed="metadata.stage !== 0"
                                  @markread="v.conversation_read = true"
                                  :notify="!v.conversation_read"
                                  :getapi="'/v2/financialclose/analysis/getconversation/'+metadata._id+'/c/'+v._id"
                                  :sendapi="'/v2/financialclose/analysis/addconversation/'+metadata._id+'/c/'+v._id"
                                  :readapi="'/v2/financialclose/analysis/markreadconversation/'+metadata._id+'/c/'+v._id"
                                />

                                <v-tooltip v-if="v.preparer?.includes(userid) && metadata.stage === 0" bottom content-class="tooltip-bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click.stop="opensendForReviewDialog(v)" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                                  </template>
                                  <span>Submit</span>
                                </v-tooltip>

                                <v-tooltip v-if="v.stage === 2 && v.approver?.includes(userid)" bottom content-class="tooltip-bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click.stop="changeChecklistStatus(v._id, 'approve')" color="success" v-bind="attrs" v-on="on" icon small><v-icon>mdi-check-circle</v-icon></v-btn>
                                  </template>
                                  <span>Approve</span>
                                </v-tooltip>

                                <v-tooltip v-if="v.stage === 2 && v.approver?.includes(userid)" bottom content-class="tooltip-bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click="changeChecklistStatus(v._id, 'reject')" color="error" v-bind="attrs" v-on="on" icon small><v-icon>mdi-close-circle</v-icon></v-btn>
                                  </template>
                                  <span>Reject</span>
                                </v-tooltip>
                                
                                  <!-- <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" icon small @click="openHelpdeskDialog(v)"><v-icon>mdi-face-agent</v-icon></v-btn>
                                    </template>
                                    <span>Virtual Concierge</span>
                                  </v-tooltip> 
                                  
                                  <v-tooltip bottom content-class="tooltip-bottom" v-if="v.botid && Object.keys(v.result).length === 0">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" icon small @click="unlinkBot(v)" color="caccounting"><v-icon>mdi-robot-happy</v-icon></v-btn>
                                    </template>
                                    <span>Bot linked</span>
                                  </v-tooltip>

                                  <v-tooltip bottom content-class="tooltip-bottom" v-else-if="Object.keys(v.result).length !== 0">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" icon small  color="green" @click="unlinkBot(v)"><v-icon>mdi-robot-happy-outline</v-icon></v-btn>
                                    </template>
                                    <span>Bot results ready</span>
                                  </v-tooltip>

                                  <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" icon small @click="openlinkBotDialog(v)"><v-icon>mdi-robot-happy-outline</v-icon></v-btn>
                                    </template>
                                    <span>No bot linked</span>
                                  </v-tooltip>
                                  
                                  <Conversation
                                    heading="Conversation"
                                    width="600"
                                    :recurring="false"
                                    :recurringText="''"
                                    :closed="metadata.stage !== 0"
                                    @markread="v.conversation_read = true"
                                    :notify="!v.conversation_read"
                                    :getapi="'/v2/financialclose/analysis/getconversation/'+metadata._id+'/c/'+v._id"
                                    :sendapi="'/v2/financialclose/analysis/addconversation/'+metadata._id+'/c/'+v._id"
                                    :readapi="'/v2/financialclose/analysis/markreadconversation/'+metadata._id+'/c/'+v._id"
                                  />

                                  <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.stage !== 3 && v.stage !== 3 && metadata.managerlist.indexOf(userid) > -1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" icon small @click="addEditForm(v)"><v-icon>mdi-pencil</v-icon></v-btn>
                                    </template>
                                    <span>Edit</span>
                                  </v-tooltip>

                                  <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.stage !== 3 && metadata.managerlist.indexOf(userid) > -1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" icon small @click="deletedialog=true;deleteitem=v;"><v-icon>mdi-delete</v-icon></v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>

                                  <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                          <lb-activitylog :config="activitylogconfig" :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${v._id}`" />
                                        </span>
                                      </template>
                                    <span>Activity Log</span>
                                    </v-tooltip>
                                  <v-btn v-if="v.upload" class="mt-1 black--text" icon small @click="$nova.downloadFiles(v.upload)"><v-icon>mdi-download</v-icon></v-btn> -->
                              </div>
                            </td>
                            <td class="text-center">
                              <v-tooltip left content-class="tooltip-left" v-if="v.stage === 0">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                </template>
                                <span>Pending Submission</span>
                              </v-tooltip>
                              <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="warning">mdi-cogs</v-icon>
                                </template>
                                <span>Processing</span>
                              </v-tooltip>
                              <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                                </template>
                                <span>Pending Approval</span>
                              </v-tooltip>
                              <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 3">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                </template>
                                <span>Closed Task</span>
                              </v-tooltip>
                            </td>
                          </template>
                        </tr>
                      </v-slide-y-transition>
                    </template>
                  </v-simple-table> 
                </div>
              </div>
              <div v-else class="pa-0">
                <div class="d-flex align-center pa-2 hoverable">
                  <span class="mr-4">Book Closure Tasklist</span>
                  <bulkChecklistUpload :project="id" @reload="refreshData()"/>
                  <v-spacer></v-spacer>
                  <!-- <v-chip v-if="checklist.length === 0" color="info" label small>Pending</v-chip>
                  <v-chip v-else color="success" label small>Uploaded</v-chip> -->
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </div>

        </v-card-text>
      </v-card>

      <lb-dialog v-model="bulkactionuserdialog" :heading="'Manage ' + bulkactionuserusertype" width="500"
        :loading="bulkactionuserloading">
        <template v-slot:body>
          <div class="switch-custom-color">
            <div class="d-flex align-center mb-1">
              <div class="display-1 mr-2">{{ multiselectdata.length || 0 }}</div>
              <div class="line-1-2">Item(s)<br />Selected</div>
              <v-spacer></v-spacer>
              <lb-dropdown hidedetails v-if="!isLocation" label="Select Action*" v-model="selectaction" :items="bulkactionuserusertype ==='Approver' ? itemapprover: itempreparer"
              itemtext="name" itemvalue="value" class="mt-2" width="10" @change="actionselection(selectaction,bulkactionuserusertype)"/>
            </div>
            <div v-if="bulkactionuserselected">
              Below selected user will be removed from all the selected items.
            </div>
            <div v-else>
              Below selected user will be added to all the selected items.
            </div>
            <lb-dropdown hidedetails label="Select User*" v-if="!isLocation" v-model="bulkactionuserid" :items="possibleusers"
              itemtext="displayname" itemvalue="_id" class="mt-2" />

              <lb-dropdown hidedetails :label="$store.state.LocationHeader ? 'Select '+$store.state.LocationHeader  :'Select Location*'" v-if=isLocation v-model="bulkactionuserid" :items="formdata.location"
                itemtext="displayname" itemvalue="_id" class="mt-2" />

              <lb-dropdown hidedetails :label="'Select ' + bulkactionuserusertype " v-model="changetouser" :items="formdata.users"
              itemtext="displayname" itemvalue="_id" class="mt-2" v-if="selectaction === 'replace'" :disabled="bulkactionuserid ? false: true" />
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
           <v-btn color="error" small v-if="selectaction === 'remove'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,multiselectdata)">Remove</v-btn>
          <v-btn color="fbcPrimary" small v-else-if="selectaction === 'associate'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,multiselectdata)">Add</v-btn>
          <v-btn color="fbcPrimary" small v-else-if="selectaction === 'replace'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,multiselectdata,changetouser)">Replace</v-btn>
          <v-btn color="fbcPrimary" small v-else-if="selectaction === 'move'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,multiselectdata)">Change</v-btn>
          <v-btn color="fbcPrimary" small v-else-if="selectaction === 'update'" @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, multiselectdata)">Update</v-btn>
        </template>
      </lb-dialog>
      
      <lb-dialog v-model="addeditdialog" :heading="addeditdata._id ? 'Update' : 'Add New'" width="600" :loading="addeditloading">
        <template v-slot:body>
          <div>
            <v-row>
              <v-col cols="12" class="my-0 py-1">
                <lb-string v-model="addeditdata.description" label="Finance Close Task Description *" :error="addeditdataerror.description"/>
              </v-col>
              <v-col cols="11" class="my-0 py-1">
                <lb-string v-model="addeditdata.category" label="Balance sheet/Income statement fbcPrimary classification*" :error="addeditdataerror.category"/>
              </v-col>
              <v-col cols="1" class="py-1 mt-n1 ml-n4">
                <v-menu
                open-on-hover
                transition="scale-transition"
                elevation-0
                max-width="300"
                offset-y
                >
                 <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" text icon color="lighten-2"  v-bind="attrs"
                    v-on="on">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
                 </template>
                 <v-card>
                  <v-card-text class="text-6 font-weight-bold">
                    "Property, plan and equipment, Trade payables, Revenue from subscription"
                  </v-card-text>
                </v-card>
              </v-menu>
              </v-col>
              <v-col cols="11" class="my-0 py-1">
                <lb-string v-model="addeditdata.subcategory" label="Balance sheet/Income statement secondary classification*" :error="addeditdataerror.subcategory"/>
              </v-col>
              <v-col cols="1" class="py-1 mt-n1 ml-n4">
                <v-menu
                open-on-hover
                transition="scale-transition"
                elevation-0
                max-width="300"
                offset-y
                >
                 <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" text icon color="lighten-2"  v-bind="attrs"
                    v-on="on">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
                 </template>
                 <v-card>
                  <v-card-text class="text-6 font-weight-bold">
                    "Non-current assets, Current liabilities, Revenue from operations etc…"
                  </v-card-text>
                </v-card>
              </v-menu>
              </v-col>  
              <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown v-model="addeditdata.location" :items="formdata.locations" itemtext="name" itemvalue="_id" :label="$store.state.LocationHeader ? $store.state.LocationHeader  :'Location*'" :error="addeditdataerror.location"/>
                </v-col>
              <v-col cols="6" class="my-0 py-1" v-if="isLocationSelected || addeditdata._id">
                <lb-dropdown v-model="addeditdata.preparer" :items="finaluserlist" itemtext="name" itemvalue="_id" multiple label="Preparer(s)" :error="addeditdataerror.preparer"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1" v-if="isLocationSelected || addeditdata._id">
                <lb-dropdown hidden v-model="addeditdata.approver" :items="finaluserlist" itemtext="name" itemvalue="_id" multiple label="Approver(s)" :error="addeditdataerror.approver"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-textarea v-model="addeditdata.purpose" label="Purpose" :error="addeditdataerror.purpose"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-textarea v-model="addeditdata.instruction" label="Instruction" :error="addeditdataerror.instruction"/>
              </v-col>
              <v-col cols="12" class="my-0 py-1">
                <lb-dropdown v-model="addeditdata.assertions" :items="possibleassertions" multiple label="Assertions" :error="addeditdataerror.assertions"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-date v-model="addeditdata.duedate" label="Due Date" :error="addeditdataerror.duedate" :min="$nova.formatDateISO(new Date())"/>
              </v-col>
              <v-col cols="12" class="my-0 py-1">
                <div class="d-flex align-center">
                  <span class="mr-2 font-weight-bold">Linked GLs:</span>

                  <v-btn class="shadow-off" v-if="filteredItems.length > 0" @click="openSelectGlDialog(addeditdata.__tbtotals?.items || [])" small text>
                    <v-icon class="mr-1">mdi-pencil</v-icon>Update TB Link</v-btn>
                     <v-btn class="shadow-off" v-else @click="NotavailablepopTb = true;" small text>
                    <v-icon class="mr-1">mdi-pencil</v-icon>Update TB Link</v-btn>
                </div>
                <div class="mt-2" v-if="selectedGlCodes.length > 0">
                  <!-- <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in (addeditdata.__tbtotals?.items || [])" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small outlined v-bind="attrs" v-on="on" class="mb-1 mr-1"><v-icon class="mr-1">mdi-tag</v-icon>{{v.glcode}}</v-chip>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip> -->
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v, k) in (selectedGlCodes || [])" :key="k">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip small outlined v-bind="attrs" v-on="on" class="mb-1 mr-1"><v-icon class="mr-1">mdi-tag</v-icon>{{ v[0].glcode }}</v-chip>
                      </template>
                    </v-tooltip>
                </div>
                <div class="mt-2" v-else>
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v, k) in (defaultGlCodes || [])" :key="k">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip small outlined v-bind="attrs" v-on="on" class="mb-1 mr-1"><v-icon class="mr-1">mdi-tag</v-icon>{{ v }}</v-chip>
                      </template>
                    </v-tooltip>
                </div>
                <!-- <div class="mt-2" v-else>
                    <v-tooltip bottom content-class="tooltip-bottom" v-for="(v, k) in (defaultGlCodes || [])" :key="k">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip small outlined v-bind="attrs" v-on="on" class="mb-1 mr-1">
                                <v-icon class="mr-1">mdi-tag</v-icon>{{ v.glcode }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </div> -->
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="fbcPrimary" @click="addEditChecklistItem()">
            <span v-if="addeditdata._id">Update</span>
            <span v-else>Add</span>
          </v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="helpdeskdialog" heading="New Query" width="600" :loading="helpdeskloading">
        <template v-slot:body>
          <div>
            <div class="body-1 mb-4">
              <span class="font-weight-bold">{{ helpdeskitem.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <span>{{helpdeskitem.description}}</span>
            </div>
            <div v-if="(helpdeskitem.__existingtickets || []).length > 0 && !helpdeskitemnew">
              <div class="py-1 d-flex align-center">
                <div class="font-weight-bold mr-2">Existing Tickets</div>
                <v-btn small text class="shadow-off" @click.stop="helpdeskitemnew=true;"><v-icon class="mr-1">mdi-plus</v-icon>Add New</v-btn>
              </div>
              <div v-for="(v,k) in helpdeskitem.__existingtickets" :key="k" class="pa-1 hoverable cursor-pointer" @click.stop="$nova.gotoLink({path: '/financialclose/functionalsupport/'+metadata._id+'/support/'+v._id})">
                <v-icon>mdi-circle-small</v-icon>
                {{ v.description }}
              </div>
            </div>
            <div v-if="helpdeskitemnew">
              <v-btn v-if="(helpdeskitem.__existingtickets || []).length > 0" text small class="shadow-off mb-2" @click="helpdeskitemnew = false;"><v-icon class="mr-1">mdi-arrow-left</v-icon>Back to existing tickets</v-btn>
              <div class="mb-3">If you need Consark's assistance to complete this task or if you have any query relating to this tasklist item, you can submit this form to connect with our functional experts for quick resolution.</div>
              <lb-textarea hidedetails v-model="helpdeskitem.__ticketdescripton" label="Discribe your request*"/>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="fbcPrimary" v-if="metadata.stage === 0" @click="addHelpdeskQuery()">Submit</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="deletedialog" heading="Delete checklist item" width="600" :loading="deleteloading">
        <template v-slot:body>
          <div>
            <div class="body-1 mb-4">
              <span class="font-weight-bold">{{ deleteitem.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <span>{{deleteitem.description}}</span>
            </div>
            <div>You are about to delete the above tasklist item from this project.</div>
            <div class="font-weight-bold">Do you want to proceed?</div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" @click="deleteChecklistItem()">Proceed</v-btn>
        </template>
      </lb-dialog>

      <v-dialog v-model="statushelpdialog" max-width="300" persistent>
        <v-card class="">
          <v-card-text class="fbcPrimary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Status Notations</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="statushelpdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div class="my-1">
              <v-icon color="info">mdi-timer-sand</v-icon>
              <span class="ml-2">Pending Submission</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-cogs</v-icon>
              <span class="ml-2">Processing</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-timer-sand</v-icon>
              <span class="ml-2">Pending Approval</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="success">mdi-check-circle</v-icon>
              <span class="ml-2">Closed Task</span>
            </div>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="commentsdialog" max-width="600" persistent>
        <v-card :loading="commentsloading" :disabled="commentsloading" loader-height="2" class="">
          <v-card-text class="fbcPrimary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Conversations</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="commentsdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4">
            <div v-if="(conversations || []).length === 0" class="title text-center my-5 grey--text">
              No conversations
            </div>
            <div v-else>
              <div class="">
                <perfect-scrollbar class="comments-container">
                  <template v-for="(v,k) in conversations">
                    <div :class="`d-flex align-center ${($store.state.user.id!==v.user)?'':'flex-row-reverse text-right'}`" :key="k">
                      <div :class="`comment-text border-on radius-on px-3 py-1 my-1 ${($store.state.user.id!==v.user)?'grey lighten-2':'text-left'}`">{{v.comment}}</div>
                      <div class="mx-1 caption">
                        {{$nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "")}}
                      </div>
                    </div>
                  </template>
                </perfect-scrollbar>
              </div>

            </div>

          </v-card-text>
          <v-card-text class="pb-1 d-flex align-center" v-if="metadata.stage === 0">
            <v-checkbox dense hide-details class="my-0 py-0"></v-checkbox>
            <span>Recurring comment</span>
            <v-tooltip right content-class="tooltip-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small><v-icon>mdi-help-circle-outline</v-icon></v-btn>
              </template>
              <div class="text-center">Tick this to add this conversation to all <br/>project's tasklist having same reference ID</div>
            </v-tooltip>
          </v-card-text>
          <v-card-text class="d-flex align-center"  v-if="metadata.stage === 0">
            <lb-string v-model="conversationtext" label="Write here..." :error="conversationtexterror" hidedetails/>
            <v-btn icon class="ml-2" color="fbcPrimary" @click="addComment()"><v-icon>mdi-send</v-icon></v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <lb-dialog v-model="pbclinkdialog" heading="Information Manager Links" width="600">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <span>{{ pbclinkdialogchecklistdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ pbclinkdialogchecklistdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 mb-2">
              <span class="font-weight-bold">{{ pbclinkdialogchecklistdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ pbclinkdialogchecklistdata.description || "" }}</div>
            </div>
            <div class="d-flex">
              <div class="display-1 mr-3 font-weight-thin">{{(pbclinkdialogchecklistdata.__pbccount || {}).total}}</div>
              <div class="flex-grow-1">
                <div style="max-width:100px">
                  {{ (((((pbclinkdialogchecklistdata.__pbccount || {}).completed || 0)/((pbclinkdialogchecklistdata.__pbccount || {}).total || 0)) || 0)*100).toFixed(0) }}% Completed
                  <v-progress-linear
                    :height="10" color="success"
                    :value="Math.max(((((pbclinkdialogchecklistdata.__pbccount || {}).completed || 0)/((pbclinkdialogchecklistdata.__pbccount || {}).total || 0)) || 0)*100,2)"
                  >
                  </v-progress-linear>
                </div>
              </div>
            </div>
            <div v-if="(pbclinkdialogitems || []).length === 0" class="title text-center my-5 grey--text">
              No Data
            </div>
            <div v-else>
              <div class="mt-4">
                <div class="font-weight-bold">Request List</div>
                <perfect-scrollbar style="max-height: 65vh">
                  <div v-for="(v,k) in pbclinkdialogitems" :key="k" class="hoverable">
                    <div class="d-flex align-start py-1 px-2">
                      <span class="mr-1">
                        <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                          </template>
                          <span>Cancelled Request</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                          </template>
                          <span>Waiting Response</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                          </template>
                          <span>Pending Approval</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                          </template>
                          <span>Approved Request</span>
                        </v-tooltip>
                      </span>
                      <div>{{ v.description }} </div>
                      <v-spacer></v-spacer>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="tblinkdialog" heading="Trial Balance Amount Breakup" width="1000">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <span>{{ tblinkdialogchecklistdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ tblinkdialogchecklistdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 mb-2">
              <span class="font-weight-bold">{{ tblinkdialogchecklistdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ tblinkdialogchecklistdata.description || "" }}</div>
            </div>
            <div class="body-1 mb-2">
              <span class="font-wight-bold">Total Closing:</span>
              {{ $nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0)) }}
            </div>
            <div v-if="(tblinkdialogitems || []).length === 0" class="title text-center my-5 grey--text">
              No Data
            </div>
            <div v-else>
              <v-simple-table dense class="FC-Table">
                <template v-slot:default>
                  <thead>
                    <th class="text-left">GL Code</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</th>
                    <th class="text-right">Opening</th>
                    <th class="text-right">Transaction</th>
                    <th class="text-right">Audit Entries</th>
                    <th class="text-right">Closing</th>
                  </thead>
                  <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                    <tr v-for="(v,k) in tblinkdialogitems" :key="k" class="">
                      <td class="">{{v.glcode}}</td>
                      <td class="">{{v.type}}</td>
                      <td class="">{{v.name}}</td>
                      <td class="" v-if="v.location_detail.length > 0">{{v.location_detail[0].name }}</td>
                      <td class="" v-else>{{  }}</td>
                      <td class="text-right">{{$nova.formatNumber(v.opening_amount.toFixed(0))}}</td>
                      <td class="text-right">{{$nova.formatNumber(v.transaction.toFixed(0))}}</td>
                      <td class="text-right">{{$nova.formatNumber(v.audit_entries.toFixed(0))}}</td>
                      <td class="text-right">{{$nova.formatNumber(v.closing_amount.toFixed(0))}}</td>  
                    </tr>
                  </v-slide-y-transition>
                </template>
              </v-simple-table> 
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="schedulelinkdialog" heading="Schedule Amount Breakup" width="800">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <span>{{ schedulelinkdialogchecklistdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ schedulelinkdialogchecklistdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 mb-2">
              <span class="font-weight-bold">{{ schedulelinkdialogchecklistdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ schedulelinkdialogchecklistdata.description || "" }}</div>
            </div>
            <div class="body-1 mb-2">
              <span class="font-wight-bold">Total Closing:</span>
              {{ $nova.formatNumber((schedulelinkdialogchecklistdata.result?.value?.total_value || 0).toFixed(0)) }}
            </div>
            <div v-if="(schedulelinkdialogitems || []).length === 0" class="title text-center my-5 grey--text">
              No Data
            </div>
            <div v-else>
              <v-simple-table dense class="FC-Table">
                <template v-slot:default>
                  <thead>
                    <th class="text-left">Particulars</th>
                    <th class="text-right">Amount</th>
                    <template v-for="(v,k) in schedulelinkdialogitems[0] || {}">
                      <th v-if="k !== 'Amount' && k !== 'Particulars' && k!=='Task No' && k!=='Task description' && k!=='Location'" :key="k">{{k}}</th>
                    </template>
                  </thead>
                  <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                    <tr v-for="(v,k) in schedulelinkdialogitems" :key="k" class="">
                      <td class="">{{v.Particulars}}</td>
                      <td class="text-right">{{$nova.formatNumber(Number(v.Amount || 0).toFixed(0))}}</td>
                      <template v-for="(vv,kk) in schedulelinkdialogitems[0] || {}">
                        <!-- {{  kk }} -->
                        <td v-if="kk !== 'Amount' && kk !== 'Particulars' && kk !== 'Task No' && kk !== 'Task description' && kk !== 'Location'" :key="kk">{{v[kk]}}</td>
                      </template>
                    </tr>
                  </v-slide-y-transition>
                </template>
              </v-simple-table> 
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="viewtaskdialog" heading="Task Details" width="800">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">Actions:</div>
            <div class="d-flex" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small @click="openHelpdeskDialog(viewtaskdata)"><v-icon>mdi-face-agent</v-icon></v-btn>
                  </template>
                  <span>Virtual Concierge</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom" v-if="viewtaskdata.botid && Object.keys(viewtaskdata.result).length === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small @click="unlinkBot(viewtaskdata)" color="caccounting"><v-icon>mdi-robot-happy</v-icon></v-btn>
                  </template>
                  <span>Bot linked</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="Object.keys(viewtaskdata.result).length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small  color="green" @click="unlinkBot(viewtaskdata)"><v-icon>mdi-robot-happy-outline</v-icon></v-btn>
                  </template>
                  <span>Bot results ready</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom" v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small @click="openlinkBotDialog(viewtaskdata)"><v-icon>mdi-robot-happy-outline</v-icon></v-btn>
                  </template>
                  <span>No bot linked</span>
                </v-tooltip>
              
                <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.stage !== 3 && viewtaskdata.stage !== 3 && metadata.managerlist.indexOf(userid) > -1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small @click="addEditForm(viewtaskdata)"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.stage !== 3 && metadata.managerlist.indexOf(userid) > -1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small @click="deleteCycleTask"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <lb-activitylog :config="activitylogconfig" :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${viewtaskdata._id}`" />
                      </span>
                    </template>
                  <span>Activity Log</span>
                  </v-tooltip>

                <v-btn v-if="viewtaskdata.upload" class="mt-1 black--text" icon small @click="$nova.downloadFiles(viewtaskdata.upload)"><v-icon>mdi-download</v-icon></v-btn>
            </div>
                              
            <div class="d-flex align-center">
              <span>{{ viewtaskdata.category }}</span>
              <span><v-icon>mdi-chevron-right</v-icon></span>
              <span>{{ viewtaskdata.subcategory }}</span>
            </div>
            <div class="d-flex body-1 mb-2">
              <span class="font-weight-bold">{{ viewtaskdata.ref }}</span>
              <span class=""><v-icon>mdi-circle-small</v-icon></span>
              <div class="">{{ viewtaskdata.description || "" }}</div>
            </div>
            <div class="mb-2">
              <div class="d-flex align-center">
                <v-chip label small class="mr-2" color="info" v-if="(viewtaskdata.preparer || []).indexOf(userid) > -1">Preparer</v-chip>
                <v-chip label small class="mr-2" color="warning" v-else-if="(viewtaskdata.approver || []).indexOf(userid) > -1">Approver</v-chip>
                <div v-if="new Date(viewtaskdata.duedate || '')">
                  <template v-if="viewtaskdata.stage === 3">
                    <v-chip v-if="(new Date(viewtaskdata.duedate || '') - new Date(viewtaskdata.reviewed_on)) < -1" label small color="error">
                      {{Math.ceil((new Date(viewtaskdata.reviewed_on || '') - new Date(viewtaskdata.duedate))/(1000*24*60*60))+1}} days delay
                    </v-chip>
                  </template>
                  <v-chip v-else-if="(new Date(viewtaskdata.duedate || '') - new Date()) >= -1" label small color="info">
                    {{Math.ceil((new Date(viewtaskdata.duedate || '') - new Date())/(1000*24*60*60))+1}} days remaining
                  </v-chip>
                  <v-chip v-else-if="(new Date(viewtaskdata.duedate || '') - new Date()) < -1" label small color="error">
                    {{Math.ceil((new Date() - new Date(viewtaskdata.duedate || ''))/(1000*24*60*60))+1}} days overdue
                  </v-chip>
                  <v-chip label small color="info" class="ml-2" v-if="viewtaskdata.locationName">
                  {{ viewtaskdata.locationName }}
                  </v-chip>
                </div>
              </div>
            </div>

            <div class="">
              <v-row>

                <v-col cols="12" class="mt-2">
                    <div class="d-flex px-2 align-center" v-if="(tblinkdialogitems || []).length > 0">
                      <div class="mr-6">
                        <div>Amount as per Trial Balance</div>
                        <div class="title" v-if="(tblinkdialogchecklistdata.__tbtotals || {}).total">{{ $nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0)) }}</div>
                        <div class="title" v-else>0</div>
                      </div>
                      <div class="mr-6">
                        <div>Amount as per Schedule</div>
                        <div class="title" v-if="Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value)">{{ $nova.formatNumber((Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value) || 0).toFixed(0)) }}</div>
                        <div class="title" v-else>0</div>
                      </div>
                      <div>
                        <div>Difference</div>
                        <div class="error--text title">{{ $nova.formatNumber((((tblinkdialogchecklistdata.__tbtotals || {}).total || 0) - (Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value) || 0)).toFixed(0)) }}</div>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <div>Threshold</div>
                        <div class="title">{{ viewtaskdata.threshold }}</div>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <v-chip label small color="error" v-if="!viewtaskdata.isMatched">Unmatched</v-chip>
                        <v-chip label small color="success" v-else>Matched</v-chip>
                      </div>
                    </div>
                    <div v-if="viewtaskdata.remark" class="px-2 mt-2">
                      <div class="body-2 pt-2">Remark</div>
                      <div>{{ viewtaskdata.remark }}</div>
                    </div>
                  </v-col>

                 <v-col cols="12" class="mt-0" v-if="(tblinkdialogitems || []).length > 0">
                  <div class="border-on shadow-on radius-on">
                    <div class="font-weight-bold px-4 py-1">
                      <div class="d-flex align-center">
                        <div class="body-2 pt-2">Linked GL(s)</div>
                        <v-spacer></v-spacer>
                        <div v-if="(tblinkdialogitems || []).length > 0">
                          <span class="font-wight-bold">Total Closing:</span>
                          {{ $nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0)) }}            
                        </div>
                      </div>
                    </div>
                    <!-- <v-divider class="mx-4"></v-divider> -->
                    <v-simple-table dense class="mx-2 mb-2 FC-Table" v-if="(tblinkdialogitems || []).length > 0">
                      <template v-slot:default>
                        <thead>
                          <th class="text-left">GL Code</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Name</th>
                          <th class="text-right">Opening</th>
                          <th class="text-right">Transaction</th>
                          <th class="text-right">Audit Entries</th>
                          <th class="text-right">Closing</th>
                        </thead>
                        <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                          <tr v-for="(v,k) in tblinkdialogitems" :key="k" class="">
                            <td class="">{{v.glcode}}</td>
                            <td class="">{{v.type}}</td>
                            <td class="">{{v.name}}</td>
                            <td class="text-right">{{$nova.formatNumber(v.opening_amount.toFixed(0))}}</td>
                            <td class="text-right">{{$nova.formatNumber(v.transaction.toFixed(0))}}</td>
                            <td class="text-right">{{$nova.formatNumber(v.audit_entries.toFixed(0))}}</td>
                            <td class="text-right">{{$nova.formatNumber(v.closing_amount.toFixed(0))}}</td>  
                          </tr>
                        </v-slide-y-transition>
                      </template>
                    </v-simple-table> 
                    <div v-else class="grey--text px-4 mb-2">No Data</div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="ml-1 pa-2 border-on shadow-on radius-on ">
                    <div class="body-2  px-1">Files uploaded</div>
                    <template v-if="(fileNamesList|| []).length > 0">
                      <div class="">
                        <div v-for="(v,k) in fileNamesList" :key="k" class="pa-1 radius-on hoverable ">
                          <span @click="$nova.downloadFile(v._id)" class="cursor-pointer">
                            {{ v.uploadname || v.uploadname || modifiedname }}
                            <v-icon>mdi-download</v-icon>
                          </span>
                        </div>
                      </div>
                    </template>
                    <div v-else class="grey--text">No Data</div>
                  </div>  
                </v-col>
                  <v-col cols="12" class="mt-2">
                  <div class="border-on shadow-on radius-on">
                    <div class="font-weight-bold px-4 py-1">
                      <div class="d-flex align-center">
                        <div class="body-2 pt-2">Schedule Breakup</div>
                        <v-spacer></v-spacer>
                        <div v-if="(schedulelinkdialogitems || []).length > 0">
                          <span class="font-wight-bold">Total Amount:</span>
                          {{ $nova.formatNumber((schedulelinkdialogchecklistdata.result?.value?.total_value || 0).toFixed(0)) }}
                        </div>
                      </div>
                    </div>
                    <!-- <v-divider class="mx-4"></v-divider> -->
                    <v-simple-table dense class="mx-2 mb-2 FC-Table" v-if="(schedulelinkdialogitems || []).length > 0">
                      <template v-slot:default>
                        <thead>
                          <th class="text-left">Particulars</th>
                          <th class="text-right">Amount</th>
                          <template v-for="(v,k) in schedulelinkdialogitems[0] || {}">
                            <th v-if="k !== 'Amount' && k !== 'Particulars'" :key="k">{{k}}</th>
                          </template>
                        </thead>
                        <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                          <tr v-for="(v,k) in schedulelinkdialogitems" :key="k" class="">
                            <td class="">{{v.Particulars}}</td>
                            <td class="text-right">{{$nova.formatNumber(Number(v.Amount || 0).toFixed(0))}}</td>
                            <template v-for="(vv,kk) in schedulelinkdialogitems[0] || {}">
                              <td v-if="kk !== 'Amount' && kk !== 'Particulars'" :key="kk">{{v[kk]}}</td>
                            </template>
                          </tr>
                        </v-slide-y-transition>
                      </template>
                    </v-simple-table> 
                    <div v-else class="grey--text px-4 mb-2">No Data</div>
                  </div>
                </v-col>

                <v-col cols="12" class=" mt-2">
                  <div class="border-on shadow-on radius-on">
                    <div class="px-4 pb-2">
                      <v-row>
                        <v-col cols="6">
                          <div class="mr-1">
                            <div class="body-2 pt-3 pb-1">Preparer(s)</div>
                            <!-- <v-divider class="mb-1"></v-divider> -->
                            <template v-if="(viewtaskdata.preparer || []).length > 0">
                              <div v-for="(v,k) in viewtaskdata.preparer" :key="k">
                                {{ userlist && userlist[v] && userlist[v].displayname }}
                              </div>
                            </template>
                            <div v-else class="grey--text">No Data</div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="ml-1">
                            <div class="body-2 pt-3 pb-1">Approver(s)</div>
                            <!-- <v-divider class="mb-1"></v-divider> -->
                            <template v-if="(viewtaskdata.approver || []).length > 0">
                              <div v-for="(v,k) in viewtaskdata.approver" :key="k">
                                {{ userlist && userlist[v] && userlist[v].displayname }}
                              </div>
                            </template>
                            <div v-else class="grey--text">No Data</div>
                          </div>  
                        </v-col>
                        <v-col cols="6">
                          <div class="mr-1">
                            <div class="body-2 pt-3 pb-1">Purpose</div>
                            <!-- <v-divider></v-divider> -->
                            <div v-if="viewtaskdata.purpose">{{viewtaskdata.purpose}}</div>
                            <div v-else class="grey--text">No Data</div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="ml-1">
                            <div class="body-2 pt-3 pb-1">Instruction</div>
                            <!-- <v-divider></v-divider> -->
                            <div v-if="viewtaskdata.instruction">{{viewtaskdata.instruction}}</div>
                            <div v-else class="grey--text">No Data</div>
                          </div>  
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" class="mt-2">
                  <div class="border-on shadow-on radius-on">
                    <div class="font-weight-bold px-4 py-1">
                      <div class="d-flex align-center">
                        <div class="body-2 pt-2">Assertions</div>
                        <v-spacer></v-spacer>
                      </div>
                    </div>
                    <div class="mx-2 mb-2" v-if="(viewtaskdata.assertions || []).length > 0">
                      <v-chip v-for="(va, ka) in viewtaskdata.assertions" :key="ka" small outlined class="pl-2 mt-2 mr-1">
                        <v-icon>mdi-check</v-icon>
                        <span class="pl-1">{{ va }}</span>
                      </v-chip>
                    </div>
                    <div v-else class="grey--text px-4 mb-2">No Data</div>
                  </div>
                </v-col>

                <!-- <v-col cols="12" class=" mt-2">
                  <div class="border-on shadow-on radius-on">
                    <div class="px-4 pb-2">
                      <v-row>
                        <v-col cols="6">
                              <div class="ml-1">
                                <div class="body-2 pt-3 pb-1">Location</div>
                                {{ viewtaskdata.locationName }}
                              </div>  
                            </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col> -->

                <v-col cols="12" class="mt-2">
                  <div class="border-on shadow-on radius-on pb-2">
                    <div class="font-weight-bold px-4 py-1">
                      <div class="d-flex align-center">
                        <div class="body-2 pt-2">Information Workflow Request List</div>
                        <v-spacer></v-spacer> 
                        <div class="d-flex" v-if="(pbclinkdialogitems || []).length > 0 && ifInformationdata">
                          <div class="display-1 mr-3 font-weight-thin">{{pbclinkdialogitems.length}}</div>
                          <div class="flex-grow-1">
                            <div style="max-width:100px">
                              {{inforeqstage }}% Completed
                              <v-progress-linear
                                :height="10" color="success"
                                :value="inforeqstage"
                              >
                              </v-progress-linear>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <v-divider class="mx-4"></v-divider> -->
                    <div v-if="(pbclinkdialogitems || []).length > 0 && ifInformationdata">
                      <div v-for="(v,k) in pbclinkdialogitems" :key="k" class="hoverable mx-2">
                        <div class="d-flex align-start py-1 px-2">
                          <span class="mr-1">
                            <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                              </template>
                              <span>Cancelled Request</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                              </template>
                              <span>Waiting Response</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                              </template>
                              <span>Pending Approval</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                              </template>
                              <span>Approved Request</span>
                            </v-tooltip>
                          </span>
                          <div class="" v-if="v.selectlist.length===0">
                          <span>{{v?.description}}</span>
                          </div>
                          <div v-else>
                            <template v-for="(item, index) in v.selectlist" >
                              <span v-if="item.location === viewtaskdata.location" :key="index">{{v.description}}</span>
                            </template>
                          </div>
                          <v-spacer></v-spacer>
                        </div>
                      </div>
                    </div>
                    <div v-else class="grey--text px-4">No Data</div>
                  </div>
                </v-col>
                <!-- <v-col cols="12" class="mt-2">
                  <div class="d-flex px-2 align-center">
                    <div class="mr-6">
                      <div>Amount as per Trial Balance</div>
                      <div class="title" v-if="(tblinkdialogchecklistdata.__tbtotals || {}).total">{{$nova.formatNumber(((tblinkdialogchecklistdata.__tbtotals || {}).total || 0).toFixed(0))}}</div>
                      <div class="title" v-else>0</div>
                    </div>
                    <div class="mr-6">
                      <div>Amount as per Schedule</div>
                      <div class="title" v-if="Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value)">{{$nova.formatNumber((Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value) || 0).toFixed(0))}}</div>
                      <div class="title" v-else>0</div>
                    </div>
                    <div>
                      <div>Difference</div>
                      <div class="error--text title">{{$nova.formatNumber((((tblinkdialogchecklistdata.__tbtotals || {}).total || 0) - (Number((schedulelinkdialogchecklistdata.result || {}).value?.total_value) || 0)).toFixed(0))}}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <div>
                      <div>Threshold</div>
                      <div class="title">{{ viewtaskdata.threshold }}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <div>
                      <v-chip label small color="error" v-if="!viewtaskdata.isMatched">Unmatched</v-chip>
                      <v-chip label small color="success" v-else>Matched</v-chip>
                    </div>
                  </div>
                  <div v-if="viewtaskdata.remark" class="px-2 mt-2">
                    <div class="body-2 pt-2">Remark</div>
                    <div>{{viewtaskdata.remark}}</div>
                  </div>
                </v-col> -->
              </v-row>              
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="selectgldialog" heading="Select GL(s) to update" width="600" persistent>
        <template v-slot:body>
          <div>
            <div class="mb-2">
              <lb-string label="Search" v-model="searchcustomfilter" hidedetails />
            </div>
            <!-- <template v-for="(v, k) in tb" :key="k">
              <div :key="k"
                v-if="(v.glcode + v.name).replaceAll(' ', '').toLowerCase().indexOf(searchcustom.toLowerCase()) > -1"> -->
                <div class="align-center mb-3 mt-3">
              <v-simple-table class="FC-Table" dense v-if="filteredItems.length > 0">
              <thead>
                <tr>
                  <th scope="Name"></th>
                  <th scope="columns">GL code</th>
                  <th scope="name">Name</th>
                  <th scope="ref">Ref</th>
                  <th scope="location">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v, k) in filteredItems">
                  <tr :key="k">
                    <td><v-checkbox class="mt-0" dense label="" v-model="selectgls" multiple :value="v._id"
                    hide-details></v-checkbox></td>
                    <td>{{ v.glcode }}</td>
                    <td>{{ v.name }}</td>
                    <td>
                      <div v-for="(gg, jj) in v.reference_detail || [] " :key="jj">
                      <span>{{gg.ref}}</span>
                      </div>
                    </td>
                    <td>
                      <div v-for="(vv, kk) in v.location_detail || [] " :key="kk">
                      <span class="font-weight-black ml-2">{{vv.name}}</span>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table>

                  <!-- <v-checkbox class="mt-0" dense label="" v-model="selectgls" multiple :value="v.glcode"
                    hide-details></v-checkbox>
                  <span class="mr-1">{{ v.glcode }}</span>-
                  <span class="ml-1">{{ v.name }}</span>
                   <div v-for="(vv, kk) in v.location_detail || [] " :key="kk">
                    <span class="font-weight-black ml-2">{{vv.name}}</span>
                  </div> -->
                </div>
                <v-divider></v-divider>
              </div>
            <!-- </template>
          </div> -->
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" @click="addSelectedGls()">Select</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="sendforreviewdialog" heading="Send task for review" width="500" persistent :loading="sendforreviewloading">
        <template v-slot:body>
          <div class="text-center">
            <div class="d-flex align-center mb-2 justify-center">
              
              <span
                :class="`mr-3 ${sendforreview ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Upload Workpaper</span>
              <v-switch inset dense class="mt-0" color="default"
                v-model="sendforreview" label="" hide-details>
              </v-switch>
              <span
                :class="`ml-1 ${!sendforreview ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Add remark & submit</span>
            </div>
             
              <div class="text-center mb-1" v-if="!sendforreview">Upload the workpaper template here.</div>
              <div class="d-flex align-center justify-center" v-if="!sendforreview">
                 <v-btn color="fbcPrimary" small class="ml-2 mr-2" @click="workpaperTemplate()">Download Template</v-btn>
                 <lb-file multiple  v-model="files[sendforreviewdata._id]" label="" :loading="fileprocessing[sendforreviewdata._id] === 1" :loadingpercent="(Number(fileprocessingpercent[sendforreviewdata._id]) || 0) + (counter * 0)"  
                :drag="false" hidedetails @change="uploadFiles(sendforreviewdata._id)" :displayname="false"
                accept=".xlsx,.xls,.xlsm,.xlsb.doc,.docx,.ppt,.pptx,.pdf" :disabled="stage !== 0"
              />
              </div>
              <div class="d-flex align-center justify-center" v-else>
                <lb-textarea v-model="sendforreviewremark" label="Remarks*"></lb-textarea>
               </div>
            </div>
        </template>
        <template v-slot:actions v-if="sendforreview">
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" :disabled="!sendforreviewremark" @click="sendForReview()">Submit</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="sendforreviewdialogbulk" heading="Send bulk task for review" width="500" persistent :loading="sendforreviewloadingbulk">
        <template v-slot:body>
          <div class="text-center">

            <div class="text-center mb-1">Upload the workpaper template here.</div>
              <div class="d-flex align-center justify-center">
                 <v-btn color="fbcPrimary" small class="ml-2 mr-2" @click="workpaperTemplate()">Download Template</v-btn>
                 <lb-file multiple v-model="filesbulk" label="" 
                  :drag="false" hidedetails @change="uploadFilesBulk(filesbulk, multiselectdata)" :displayname="false" :loading="bulkfilesloading === 0"
                  accept=".xlsx,.xls,.xlsm,.xlsb.doc,.docx,.ppt,.pptx,.pdf" :disabled="stage !== 0"
                />
               </div>
            <!-- <div class="d-flex align-center mb-2 justify-center">
              <span
                :class="`mr-3 ${sendforreview ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Upload Workpaper</span>
              <v-switch inset dense class="mt-0" color="default"
                v-model="sendforreview" label="" hide-details>
              </v-switch>
              <span
                :class="`ml-1 ${!sendforreview ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Add remark & submit</span>
            </div> -->
            <!-- <div class="d-flex align-center justify-center">
              <lb-file multiple v-model="filesbulk" label="" 
                :drag="false" hidedetails @change="uploadFilesBulk(filesbulk,multiselectdata)" :displayname="false" :loading=" bulkfilesloading === 0"
                accept=".xlsx,.xls,.xlsm,.xlsb.doc,.docx,.ppt,.pptx,.pdf" :disabled="stage !== 0"
              /> -->
              <!-- <lb-textarea v-else v-model="sendforreviewremark" label="Remarks*"></lb-textarea> -->
            <!-- </div> -->
          </div>
        </template>
        <!-- <template v-slot:actions v-if="sendforreview">
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" :disabled="!sendforreviewremark" @click="sendForReview()">Submit</v-btn>
        </template> -->
      </lb-dialog>

      <lb-dialog v-model="linkbotdialog" heading="Select a bot to link" width="500" persistent :loading="linkbotloading">
        <template v-slot:body>
          <div class="text-center">
            <div class="d-flex align-center justify-center">
              <lb-dropdown v-model="selectedbot" label="Select Bot*" :items="botlist"></lb-dropdown>
            </div>
          </div>
          <div v-if="selectedbot === '6470e55d999d5833d9d58224'">
            <div class="mt-2 mb-2"><strong>Select Bot Result(s):</strong></div>
              <v-checkbox v-for="lease in leaseScheduleItems || []" :key="lease" :label="lease" dense
                class="d-inline-block ml-2" :value="lease"
                v-model="leaseScheduleValue" multiple hide-details
              ></v-checkbox>
            </div>
          <div class="mt-4 ">
            Need additional bots? Contact us <v-btn x-small text class=" ml-1 shadow-off" outlined @click="$nova.gotoLink({path: '/financialclose/functionalsupport/'+metadata._id})">here</v-btn>
          </div>
        </template>
        <template v-slot:actions v-if="sendforreview">
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" @click="linkBot()">Link</v-btn>
        </template>
      </lb-dialog>

       <lb-dialog v-model="bulklinkbotdialog" heading="Select a bot to bulk link" width="500" persistent :loading="bulklinkbotloading">
        <template v-slot:body>

           <div class="text-center">
            <div class="d-flex align-center justify-center">
              <lb-dropdown v-model="selectedbot" label="Select Bot*" :items="botlist"></lb-dropdown>
            </div>
          </div>
          <div class="mt-2 mb-2" v-if="selectedbot === '6470e55d999d5833d9d58224'"><strong>Select Bot Result(s):</strong></div>
          <div v-for="(task,i) in addeditdata" :key="i">
            <div class="mb-5">
            <strong>Task:{{getTaskDeatils(task).ref}}</strong>
            </div>
          <div v-if="selectedbot === '6470e55d999d5833d9d58224'" class="mb-3">
              <v-checkbox v-for="lease in leaseScheduleItems || []" :key="lease" :label="lease" dense
                class="d-inline-block ml-2" :value="lease"
                v-model="leaseSchedulebulkValue[getTaskDeatils(task)._id]" multiple hide-details
              ></v-checkbox>
            </div>
            </div>
          <div class="mt-4 ">
            Need additional bots? Contact us <v-btn x-small text class=" ml-1 shadow-off" outlined @click="$nova.gotoLink({path: '/financialclose/functionalsupport/'+metadata._id})">here</v-btn>
          </div>
        </template>
        <template v-slot:actions v-if="sendforreview">
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="fbcPrimary" @click="bulklinkBot()">Link</v-btn>
        </template>
      </lb-dialog>



        <lb-dialog v-model="NotavailablepopTb" heading="Trial balance not available" width="350" persistent>
        <template v-slot:body>
          <div class="text-6 font-weight-bold" v-if="addeditdata.location != ''">
          Trial balance/Chart of accounts not available to retrieve General Ledger information
          </div>
          <div class="text-6 font-weight-bold" v-else>
            Please select {{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}} for getting Trail Balance data/Chart of accounts
          </div>
        </template>
        <!-- <template v-slot:actions v-if="sendforreview">
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="primary" >Link</v-btn>
        </template> -->
      </lb-dialog>



    </div>
  </div>
</template>

<script>
let styledxlsx = require('sheetjs-style');
// import pbc from '../../../pbc/views/ProjectView';
import bulkChecklistUpload from '../Components/BulkUploadChecklist.vue';
import Conversation from "../../../../views/common/Conversation.vue";
// import bulkChecklistUpload from './Components/BulkUploadChecklist';


export default {
  name: 'financialclose_project_checklist',
  // props: ['props'],
  data: function(){
    return {
      right:"financialclose_analysis",
      metadata: {},
      tb: [],
      pbc: [],
      tbjson: {},
      checklist: [],
      reflist: [],
      summary: {},
      pbcdata: {},
      formattedchecklistdata: [],
      // pbc: {},
      fileprocessing: {},
      fileprocessingpercent: {},
      stagefilter: [0,1,2,3],
      userid: "",
      statushelpdialog: false,
      multiselectdata: [],
      commentsdialog: false,
      commentsloading: false,
      conversations: [],
      conversationtext: "",
      conversationtexterror: "",
      viewtaskdata: {},
      viewtaskdialog: false,
      editkey: -1,
      addeditdata: {},
      addeditdataerror: {},
      addeditdialog: false,
      addeditloading: false,
      deletedialog: false,
      deleteloading: false,
      deleteitem: {},
      helpdeskdialog: false,
      helpdeskloading: false,
      helpdeskitemnew: false,
      helpdeskitem: {},
      formdata: {},
      eligibleusers:{},
      userlist: {},
      locationlist: {},
      stage: -1,      
      files: {},
      filesbulk:{},
      id: "",
      clientId:"",
      loading: false,
      counter: 0,
      alltaskassigned: true,
      alltaskopen: true,
      tblinkdialog: false,
      tblinkdialogitems: [],
      tblinkdialogchecklistdata: {},
      schedulelinkdialog: false,
      schedulelinkdialogitems: [],
      schedulelinkdialogchecklistdata: {},
      pbclinkdialog: false,
      pbclinkdialogitems: [],
      pbclinkdialogchecklistdata: {},
      possibleassertions: ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'],
      activitylogconfig: {
        checklistedit: {  icon: "mdi-pencil", color: "fbcPrimary", displaytext: "Checklist Updated" },
        bulkapprove: {  icon: "mdi-tag-check-outline ", color: "fbcPrimary", displaytext: "Bulk Approve" },
        bulkreject: {  icon: "mdi-close-box-multiple-outline  ", color: "fbcPrimary", displaytext: "Bulk Reject" },
        checklistsentforreview: {  icon: "mdi-file-eye", color: "fbcPrimary", displaytext: "Checklist Sent For Review" },
        fileupload: {  icon: "mdi-file-upload", color: "fbcPrimary", displaytext: "File Upload" },
      },
      selectgldialog: false,
      searchcustom: "",
      searchcustomfilter:"",
      selectgls: [],
      selectedGlCodes:[],
      sendforreviewloading: false,
      sendforreviewloadingbulk: false,
      sendforreviewdialog: false,
      sendforreviewdialogbulk: false,
      sendforreview: true,
      sendforreviewdata: {},
      sendforreviewremark: "",
      linkbotdialog: false,
      bulklinkbotdialog: false,
      selectedbot: "",
      linkbotloading: false,
      bulklinkbotloading: false,
      botlist: [
        {name: 'Lease Schedule', value: '6470e55d999d5833d9d58224'},
        {name: 'Borrowing Schedule', value: '6474cf6a1117a96cfd35795d'},
        {name: 'Transaction Matching', value: '6474e49c00b1f12f6a2a8065'},
      ],
      possibleusers: [],
      bulkactionuserdialog: false,
      bulkactionuserid: '',
      bulkactionuserloading: false,
      bulkactionuserusertype: '',
      bulkactionuserselected: false,
      isLocation: false,
      isLocationSelected:false,
      selectaction:'add',
      changetouser:'',
      itemapprover:[
        { value:"associate",name:"Add" },
        { value:"remove",name:"Remove" },
        { value:"replace",name:"Replace" },
        { value:"move",name:"Change to Approver" }
      ],
      itempreparer:[
        { value:"associate",name:"Add" },
        { value:"remove",name:"Remove" },
        { value:"replace",name:"Replace" },
        { value:"move",name:"Change to Preparer" }
      ],
      leaseScheduleValue:[],
      leaseSchedulebulkValue:{},
      leaseScheduleItems:[
        "BS-ROU Asset",
        "BS-Lease Liability",
        "PL-Amortization Cost",
        "PL-Interest Expense",
        "BS-Security Deposit",
        "BS-SD-Interest Income"
      ],
      NotavailablepopTb:false,
      dataArray:[],
      bulkfilesloading:1,
      filterList:[],
      filterListitems:[],
      filterSelectedData : { "selectedlocation": "All", "selecteduser": "All" },
      userSearch:"",
      locationSearch:"",
      defaultGlCodes:[],
      fileNamesList :[],
      infomangerrequests:[],
      inforeqstage:0,
      Tbheaders:[
        "TB Amount",
        "Schedule Amount",
        "Difference",
      ],
      showTbHeaders:false,
      ifInformationdata:false,
    }
  },
  components: {
    // pbc,
    bulkChecklistUpload, Conversation
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
 

  methods: {
    filters() {
    this.filterList = [...this.formattedchecklistdata];
    if (this.filterSelectedData.selectedlocation !== "All") {
        this.filterList = this.filterList.filter(x => x.locationName === this.filterSelectedData.selectedlocation);
    }
    if (this.filterSelectedData.selecteduser !== "All") {
        let filteredList = [];
        let selectedUser = this.filterSelectedData.selecteduser;
        this.filterList.forEach(x => {
            let users = [...(x.preparer || []), ...(x.approver || [])];
            if (users.includes(selectedUser)) {
                filteredList.push(x);
            }
        });
        this.filterList = filteredList;
      }
    },
     resetFilter(){
      this.filterSelectedData.selecteduser = 'All'
      this.filterSelectedData.selectedlocation = 'All'
      this.filterList = this.formattedchecklistdata;
    },
    filterWithLocation(location){
      this.filterSelectedData.selectedlocation = location;
      this.filters();
    },
     filterWithuser(user){
       this.filterSelectedData.selecteduser = user;
       this.filters();
    },
      filterformated() {
      let locations = new Set(['All']);
      let userlists = new Set(['All']);
      const filteredData = this.formattedchecklistdata.map(item => {
          if (item.locationName) locations.add(item.locationName);
          if (item.preparer) item.preparer.forEach(user => userlists.add(user));
          if (item.approver) item.approver.forEach(user => userlists.add(user));
          return {
              _id: item._id,
              approver: item.approver,
              locationName: item.locationName,
              preparer: item.preparer,
          };
      });
      this.filterListitems = {
          locations: [...Array.from(locations)],
          userlists: [...Array.from(userlists)]
      };
      this.filterList = filteredData;
      this.filters();
    },
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.clientId = this.$store.state.client;
      console.log(this.$store)
      this.multiselectdata = [];
      this.getData();
    },
    viewTask(dt={}){
      this.inforeqstage=0;
      this.pbclinkdialogitems=[];
      console.log(dt);
      this.viewtaskdata = {...dt};
      this.fileNamesList=[]
      if(dt.stage ===2 || dt.stage===3){
      this.axios.post("/v2/upload/filenames/"+this.viewtaskdata.upload).then(dt => {
        this.fileNamesList = dt.data.data
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      }
      if (this.infomangerrequests.some(item => item?.selectlist?.length > 0)) {
        this.ifInformationdata = this.infomangerrequests.some(item =>
          item?.selectlist?.some(subItem => {
            return subItem?.location === dt.location;
          })
        );
      } else {
        this.ifInformationdata = true;
      }
      this.populatepbclinkdialogitems(dt, false);
      this.populatetblinkdialogitems(dt, false);
      this.populateschedulelinkdialogitems(dt, false);
      this.viewtaskdata["isMatched"]= Math.abs((this.tblinkdialogchecklistdata.__tbtotals?.total - Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || this.tblinkdialogchecklistdata.__tbtotals?.total || Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || 0))< dt.threshold ? true:false;
      this.infomanagerReq(dt);
      this.viewtaskdialog = true;

    },
    infomanagerReq(dt){
      this.pbclinkdialogitems = this.infomangerrequests.filter((item) => {
      const searchId = dt.master_id;
      const selectlistMatch = item.selectlist.filter(subItem => subItem.selectlist === searchId  && subItem.location === dt.location);
      const referenceMatch = item.reference.includes(dt.id);
      return selectlistMatch.length >0 ? selectlistMatch : referenceMatch
    });

      this.pbclinkdialogitems.forEach((item)=>{
        item.stage===2 && this.inforeqstage++
      })
      let progress = (this.inforeqstage/this.pbclinkdialogitems.length )*100
      this.inforeqstage= Math.round(progress)
    },
    deleteCycleTask(){
      this.deletedialog=true; 
      this.deleteitem=this.viewtaskdata
    },
    openlinkBotDialog: function(d){
      this.addeditdata = {...d};
      this.selectedbot = this.addeditdata.botid;
      this.linkbotdialog = true;
      this.leaseScheduleValue = [];
    },
     bulkopenlinkBotDialog: function(d){
       this.addeditdata = {...d};
       this.selectedbot = this.addeditdata.botid;
      this.bulklinkbotdialog = true;
      this.leaseScheduleValue = [];
    },
    opensendForReviewDialog: function(d){
      this.sendforreviewdata = {...d};
      this.sendforreviewremark = this.sendforreviewdata.remark;
      this.sendforreview = false;
      this.sendforreviewdialog = true;
    },
    openSelectGlDialog: function(){
      this.selectgls = [];
      this.searchcustom = "";
      this.searchcustomfilter = "";
      this.filteredItems;
      if (Object.keys(this.addeditdata).includes("__tbtotals")) {
        for (const i of this.addeditdata.__tbtotals.items) {
          this.selectgls.push(i._id);
        }
      }
      this.selectgldialog = true;
    },
    addSelectedGls: function(){
      let tbtotals = {total: 0, items: []};
      this.selectedGlCodes = []
      for (const i of this.selectgls || []) {
        this.selectedGlCodes.push(this.filteredItems.filter(item => item._id === i));
      }
      for (const i of this.selectgls || []) {
        tbtotals.items.push(this.tbjson[i]);
        tbtotals.total = tbtotals.total + i[0].closing_amount || 0;
      }
      this.addeditdata.__tbtotals = tbtotals;
      this.selectgldialog = false;
    },
    addEditForm(dt={}){
       console.log(dt);
      this.addeditdataerror = {};
      this.addeditdata = {...dt};
      console.log(JSON.stringify(this.addeditdata))
      this.selectedGlCodes = []
      this.defaultGlCodes = this.addeditdata.glcode ?? [];
      for (const i of this.addeditdata.__tbtotals?.items || []) {
        console.log(JSON.stringify(i.glcode) + "selected gls")
        this.selectedGlCodes.push(this.filteredItems.filter(item => item._id === i._id));
      }
      //this.addeditdata.location = "64510941c50ca87e50dcec675";
      this.isLocationSelected=false;
      this.addeditdialog = true;
    },
    addEditChecklistItem() {
      this.addeditloading = true;
      let type = "add";
      let url = "/v2/financialclose/analysis/addchecklist/"+this.id;
      if(this.addeditdata._id) {
        type = "edit";
        url = "/v2/financialclose/analysis/editchecklist/"+this.addeditdata._id;
      }
      let ndt = {...this.addeditdata};
      let tblinks = [];
      for (const i of ndt.__tbtotals?.items || []) {
        tblinks.push(i._id);
      }
      ndt.__tblinks = tblinks;
      delete ndt._id;
      this.axios.post(url, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          if(type === "add") this.$store.commit("sbSuccess", "Tasklist item added");
          else this.$store.commit("sbSuccess", "Tasklist item updated");
          this.addeditdialog = false;
          delete ndt.__tblinks;
           this.viewtaskdialog = false;
          // this.checklist[this.editkey] = {...this.addeditdata};
          // this.calculateDetails();
          // this.checklist.push({});
          // this.checklist.pop();
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditdataerror = dt.data.data[0].index0;
          }else throw new Error (dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditloading = false;
      })
    },
    downloadPPT() {
      if(!this.alltaskassigned) this.$store.commit("sbError", "Assign all tasks before generating the PPT");
      else if(this.alltaskopen) this.$store.commit("sbError", "No task closed to generate the PPT");
      else this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/downloadppt/'+this.metadata._id);
    },
    workpaperTemplate(){
      this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/gettasklisttemplate');
    },
     bulkActionUserdilog(type, isLocation){
      this.isLocation = isLocation || false
      this.selectaction = 'associate';
      this.bulkActionUser(type);
    },
    actionselection(select,type){
      if (select === 'remove') {
          this.bulkactionuserselected = true;
          this.bulkActionUser(false);
      } else{
         this.bulkActionUser(type);
      }
    },
    bulkActionUser(type) {
      let selecteditems = this.multiselectdata;
      let existingusers = new Set();
      let associatedusers = [...(this.metadata.managerlist || []), ...(this.metadata.userlist || [])];
      let possibleids = [];
      if (type) {
        this.bulkactionuserselected = false;
        if (type === 'preparer') this.bulkactionuserusertype = "Preparer";
        else if (type === 'approver') this.bulkactionuserusertype = "Approver";
      }
      for (const i of this.checklist) {
        if (selecteditems.indexOf(i._id) > -1) {
          if (this.bulkactionuserusertype === 'Preparer') (i.preparer || []).forEach(existingusers.add, existingusers);
          else if (this.bulkactionuserusertype === 'Approver') (i.approver || []).forEach(existingusers.add, existingusers);
        }
      }
      if (!this.bulkactionuserselected) {
          for (const i of (this.formdata.users || [])) {
            if (associatedusers.indexOf(i._id) > -1) this.possibleusers.push(i);
          }
      } else {
        possibleids = Array.from(existingusers);
        // console.log(possibleids);
        this.possibleusers = [];
        for (const i of (this.formdata.users || [])) {
          // console.log(i);
          if (possibleids.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      }
      this.bulkactionuserdialog = true;
    },
  bulkManageUser(action, usertype, userid, selectitems, changeuserid) {
      let ndt = {};
      this.bulkactionuserloading = true;
      if(usertype==="Location"){
        if(userid===""){ 
          this.bulkactionuserloading = false;
          throw new Error("Please select location");
        }
      }
      if (action === 'replace') {
        ndt = { fromuserid: userid, touserid: changeuserid, type: action };
      } else {
        ndt = { userid: userid, usertype: (usertype || "").toLowerCase(), type: action };
      }
      let fdt = { type: "checklist", _id: selectitems };

      console.log(ndt,fdt);
      let successflag = false;
      this.axios.post("/v2/financialclose/analysis/bulkmanageuser/" + this.metadata._id, { data: [ndt], filter: fdt })
        .then(dt => {
          if (dt.data.status === "success") {
            this.bulkactionuserdialog = false;
            let successMessage = "";
            if (action === "associate") successMessage = "User Added";
            else if (action === "remove") successMessage = "User Removed";
            else if (action === "move") successMessage = "Change to " + usertype;
            else if (action === "replace") successMessage = "User Replaced";
            this.$store.commit("sbSuccess", successMessage);
            this.changetouser = "";
            successflag = true;
          } else {
            throw new Error(dt.data.message || "Error changing status");
          }
        })
        .catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.bulkactionuserloading = false;
          if (successflag) this.refreshData();
        });
  },
    // bulkManageUser(action) {
    //   let ndt = {}
    //   this.bulkactionuserloading = true;
    //   if (action === "remove") ndt = { itemids: this.multiselectdata, action: action, type: (this.bulkactionuserusertype || "").toLowerCase(), userid: this.bulkactionuserid };
    //   else if (action === "add") ndt = { itemids: this.multiselectdata, action: action, type: (this.bulkactionuserusertype || "").toLowerCase(), userid: this.bulkactionuserid };
    //   let successflag = false;
    //   this.axios.post("/v2/financialclose/analysis/bulkmanageuser/" + this.metadata._id, { data: [ndt] }).then(dt => {
    //     if (dt.data.status === "success") {
    //       this.bulkactionuserdialog = false;
    //       if (action === "add") this.$store.commit("sbSuccess", "User Added");
    //       else if (action === "remove") this.$store.commit("sbSuccess", "User Removed");
    //       successflag = true;
    //     } else throw dt.data.message || "Error changing status";
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     // console.log(err);
    //   }).finally(() => {
    //     this.bulkactionuserloading = false;
    //     if (successflag) this.refreshData();
    //   });
    // },
    deleteChecklistItem() {
      this.deleteloading = true;
      let url = "/v2/financialclose/analysis/deletechecklist/"+this.id+'/c/'+this.deleteitem._id;
      this.axios.post(url, {}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Tasklist item deleted");
          this.deletedialog = false;
          this.viewtaskdialog=false
          this.refreshData();
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.deleteloading = false;
      })
    },
    linkBot() {
      this.linkbotloading = true;
      let url = "/v2/financialclose/analysis/linkbot/"+ this.id;
      this.axios.post(url, {data: [{botid: this.selectedbot,task_id:this.addeditdata._id,bot_result_tags:this.leaseScheduleValue}]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Bot Linked");
          this.linkbotdialog = false;
          this.checklist[this.addeditdata.__key].botid  = this.selectedbot;
          this.selectedbot = "";
          this.calculateDetails();
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.linkbotloading = false;
      })
    },
    async bulklinkBot() {
      try {
        this.bulklinkbotloading = true;
        let successMessageSent = false; 
        const url = "/v2/financialclose/analysis/linkbot/" + this.id;
        const requests = Object.keys(this.addeditdata).map(async (key) => {
          const value = this.addeditdata[key];
          const response = await this.axios.post(url, {
            data: [{ botid: this.selectedbot, task_id: value, bot_result_tags: this.leaseSchedulebulkValue[value]  }],
          });
          if (response.data.status === "success" && !successMessageSent) {
            this.$store.commit("sbSuccess", "Bulk Bot Linked");
            successMessageSent = true;
            this.bulklinkbotdialog = false;
            this.leaseSchedulebulkValue = {};
            // this.checklist[this.addeditdata.__key].botid = this.selectedbot;
            this.selectedbot = "";
            this.calculateDetails();
            this.getData();
            this.bulklinkbotloading = false;
          } else {
            throw Error(response.data.message || "Unknown error");
          }
        });
        await Promise.all(requests);
        console.log("All requests resolved successfully");
      } catch (err) {
        //this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.error(err);
      } finally {
        this.bulklinkbotloading = false;
      }
    },
    unlinkBot(v) {
      this.loading = true;
      let url = "/v2/financialclose/analysis/unlinkbot/"+ this.id;
      this.axios.post(url, {data: [v._id]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Bot Unlinked");
          this.checklist[v.__key].botid  = "";
          this.selectedbot = "";
          this.calculateDetails();
          this.getData();
          this.loading = false;
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    bulkunlinkBot(v) {
      this.loading = true;
      let url = "/v2/financialclose/analysis/unlinkbot/"+ this.id;
      this.axios.post(url, {data: [v]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Bot Unlinked");
          //  this.checklist[v.__key].botid  = "";
          this.selectedbot = "";
          this.calculateDetails();
          this.getData();
          this.loading = false;
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
   
    
    sendForReview() {
      this.sendforreviewloading = true;
      let url = "/v2/financialclose/analysis/sendforreview/" + this.id + "/c/" + this.sendforreviewdata._id;
      this.axios.post(url, {data: {remark: this.sendforreviewremark}}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Tasklist sent for review");
          this.sendforreviewdialog = false;
          this.sendforreviewdata.stage = 2;
          this.checklist[this.sendforreviewdata.__key] = {...this.sendforreviewdata};
          this.calculateDetails();
          // this.refreshData();
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.sendforreviewloading = false;
      })
    },
    addHelpdeskQuery() {
      this.helpdeskloading = true;
      let ndt = {issue: this.helpdeskitem.description, description: this.helpdeskitem.__ticketdescripton, modulename: this.$route.meta.modulename};
      let url = "/v2/financialclose/analysis/addfunctionalquery/"+this.metadata._id+"/c/"+this.helpdeskitem._id;
      this.axios.post(url, {data: ndt}).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Ticket raised");
          this.helpdeskdialog = false;
          this.$nova.gotoLink({path: "/financialclose/functionalsupport/"+this.metadata._id+"/support/"+dt.data.data[0]._id});
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.helpdeskloading = false;
      })
    },
    openHelpdeskDialog(d){
      this.helpdeskitem={...d};
      this.helpdeskdialog=true;
      this.helpdeskitemnew = false;
      this.getExistingHelpDesklist();
    },
    getExistingHelpDesklist() {
      this.helpdeskloading = true;
      let url = "/v2/financialclose/analysis/getexistingtickets/"+this.metadata._id+"/c/"+this.helpdeskitem._id;
      this.axios.post(url).then(dt => {
        if(dt.data.status === "success"){
          this.helpdeskitem.__existingtickets = dt.data.data || [];
          if((this.helpdeskitem.__existingtickets || []).length === 0) this.helpdeskitemnew = true;
        }else throw Error(dt.data.message || "Unknown error")
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.helpdeskloading = false;
      })
    },    
    getData() {
      this.loading = true;
     
      return this.axios.post("/v2/financialclose/analysis/getdetails/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          if(this.metadata.stage === 3) this.stagefilter = [0,1,2,3];
          this.stage = this.metadata.stage;
          this.tb = data.tb || [];
          if(data.tb.length > 0){
            this.showTbHeaders=true
          }
          this.pbc = data.pbcitems || [];
          this.checklist = data.checklist || [];
          this.summary = data.summary || {};
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).then(() => {
        return this.axios.post("/v2/financialclose/analysis/getformdata");
      }).then(dt => {
        if(dt.data.status === "success"){
          this.formdata = dt.data.data[0] || {};
          this.formdata.users.push({"name":this.$store.state.user.name, "_id": this.$store.state.user.id, "displayname": this.$store.state.user.name+"("+this.$store.state.user.email+")", "email":this.$store.state.user.email});
          this.formdata.locations = this.formdata.locations || [] 
          this.userlist = {};
        }else throw new Error(dt.data.message);
      }).then(() => {
        return this.axios.post("/v2/financialclose/location/get").then((dt) => {
          if (dt.data.status === "success") {
            this.formdata.locations = dt.data.data || [];
            console.log(this.formdata.locations)
            this.location = {};
            this.loading = false;
          } else throw new Error(dt.data.message);
        });
      }).then(()=>{
          this.axios.post("/v2/financialclose/project/threshold/get/" + this.id,{"enable":true}).then((dt) => {
            if (dt.data.status === "success") {
              this.locationListdata = dt.data.data;
              let checklistMapping = this.checklist.filter(obj1 => {
                obj1.threshold='NA'
                return this.locationListdata.filter(obj2 => {
                  if (obj2.location === obj1.location) {
                    obj1.threshold = obj2.threshold
                  }
                  return obj1;
                });
              })
              this.checklist = checklistMapping;
            }
          }).then(()=>{
            this.axios.post("/v2/financialclose/masters/threshold/get",{"enable":true}).then((dt) => {
              if (dt.data.status === "success") {
                this.locationListdata = dt.data.data;
                let checklistMapping = this.checklist.filter(obj1 => {
                  if(obj1.threshold === 'NA'){
                    obj1.threshold =0;
                    return this.locationListdata.filter(obj2 => {
                      if (obj2.location === obj1.location) {
                        obj1.threshold = obj2.threshold
                      }
                      return obj1;
                    });
                  }else{
                    return obj1;
                  }
                })
                this.checklist = checklistMapping;
              }
            });
          })
      }).then(()=>{
            this.axios.post("/v2/financialclose/pbc/project/getrequests/"+this.metadata.pbcproject).then((dt) => {
              if (dt.data.status === "success") {
                this.infomangerrequests = dt.data.data.map(({ _id, category, description, stage, selectlist, reference }) => ({
                  _id,
                  category,
                  description,
                  stage,
                  selectlist,
                  reference
                }));
              }
              console.log("oiuytresdf",this.infomangerrequests);
            });
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.calculateDetails();
        this.loading = false;
      })
    },
    calculateDetails(){
      let tbtotals = {};
      let closing_amount_to_subtract = []
      let tb_cat_subcat_sum = {};
      for (const i of this.tb) {
        this.tbjson[i._id] = i;
        let closing_info = {category: i.category, subcategory: i.subcategory, closing_amount: 0}
        if (i.reference_detail.length > 0 ){
          // console.log(JSON.stringify(i))
          for (const j of (i.reference_detail || [])) {
            tbtotals[j._id] = tbtotals[j._id] || {total: 0, items: []};
            tbtotals[j._id].items.push(i);
            tbtotals[j._id].total = tbtotals[j._id].total + i.closing_amount || 0;
            closing_info.closing_amount = tbtotals[j._id].total
          }
          closing_amount_to_subtract.push(closing_info)
        }
        const category = i.category;
        const subcategory = i.subcategory;
        const location = 'location' in i ? i.location : '';
        const key = `${category}-${subcategory}`;
        tb_cat_subcat_sum[key] = tb_cat_subcat_sum[key] || {
          sum: 0,
          category: category,
          subcategory: subcategory,
          locations: {}
        };
        tb_cat_subcat_sum[key].sum += i.closing_amount || 0;
        if (location) {
          tb_cat_subcat_sum[key].locations[location] = (tb_cat_subcat_sum[key].locations[location] || 0) + (i.closing_amount || 0);
        }
        // console.log("This is tb_cat_subcat: ",JSON.stringify(tb_cat_subcat_sum))
      }
      for (const ele of closing_amount_to_subtract) {
        if (tb_cat_subcat_sum[`${ele.category}-${ele.subcategory}`]) {
          tb_cat_subcat_sum[`${ele.category}-${ele.subcategory}`].sum -= ele.closing_amount;
        }
      }
      this.pbcdata = {};
      let pbccount = {};
      for (const i of (this.pbc || [])) {
        this.pbcdata[i._id] = i;
        for (const j of (i.reference || [])) {
          pbccount[j] = pbccount[j] || {total: 0, completed: 0};
          pbccount[j].total++;
          if(i.stage === 2) pbccount[j].completed++;
        }
      }
      for (const x of this.checklist) {
        if((x.preparer || []).length === 0 || (x.approver || []).length === 0) this.alltaskassigned = false;
        if(x.stage === 3) this.alltaskopen = false;
        this.reflist.push(x.ref);
        x.__pbccount = pbccount[x._id] || {};
        if(pbccount[x._id]) console.log(pbccount[x._id]);
        if(!Object.keys(tbtotals[x._id] || {}).includes("total")){
          tbtotals[x._id] = { total: 0, items: [] };
          let tbitem = this.tb.filter(item => {
            if (Object.keys(item).includes("location")) {
              if (item.category === x.category && item.subcategory === x.subcategory && item.location[0] === x.location) {
                return item
              }
            }
          });
          if (Object.keys(tb_cat_subcat_sum).includes(`${x.category}-${x.subcategory}`)) {
            if (x.location in tb_cat_subcat_sum[`${x.category}-${x.subcategory}`].locations) {
              tbtotals[x._id] = { total: 0, items: [] };
              for (const j of tbitem) {
                tbtotals[x._id].items.push(j);
                tbtotals[x._id].total = tbtotals[x._id].total + j.closing_amount || 0;
              }
            }
          }
        }
        x.__tbtotals = tbtotals[x._id] || {};
      }

      let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
      // console.log(colors);
      for (let i = 0; i < (this.formdata.users || []).length; i++) {
        const el = (this.formdata.users || [])[i];
        this.userlist[el._id] = el;
        this.userlist[el._id].color = colors[i];  
        //const ll= (this.formdata.location)
        //this.locationlist[ll.location] = ll         
      }
      this.formatChecklistData();
    },
    changeChecklistStatus(analysis, type) {
      this.loading = true;
      let idtxt = analysis;
      let data = {type: type};
      if(analysis instanceof Array) {
        idtxt = "bulk";
        data.checklist = analysis;
      }
      this.axios.post("/v2/financialclose/analysis/changecheckliststage/"+this.metadata._id+"/a/"+idtxt, {data: [data]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Status Updated");
        }else throw dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.refreshData();
      });
    },
    populatepbclinkdialogitems(data = {}, opendialog=true){
      let items = []
      for (const k in this.pbcdata) {
        if (Object.hasOwnProperty.call(this.pbcdata, k)) {
          const el = this.pbcdata[k];
          if((el.reference || []).indexOf(data._id) > -1) items.push(el);
        }
      }
      this.pbclinkdialogitems = items;
      this.pbclinkdialogchecklistdata = data;
      if(opendialog) this.pbclinkdialog = true;
    },

    populatetblinkdialogitems(data = {}, opendialog = true){
      console.log(data,"data");
      // this.filterloaction(data.location,((data.__tbtotals || {}).items || []));
      // console.log(this.filterloaction(data.location,((data.__tbtotals || {}).items || [])),"GGGGGGGGG");
      this.tblinkdialogitems = [];
      this.tblinkdialogitems = (data.__tbtotals || {}).items || [];
      this.tblinkdialogchecklistdata = data;
      if(opendialog) this.tblinkdialog = true;
    },
    populateschedulelinkdialogitems(data = {}, opendialog = true){
      this.schedulelinkdialogitems = [];
      this.schedulelinkdialogitems = data.result?.value?.fc_table || [];
      this.schedulelinkdialogchecklistdata = data;
      if(opendialog) this.schedulelinkdialog = true;
    },

    // uploadFile: function(id){
    //   let file = this.files[id];
    //   let ext = ((file || {}).name || "").split(".").pop();
    //   if((file || {}).name && (this.fileprocessing[id] || 0) === 0){
    //     this.fileprocessing[id] = 1;
    //     this.sendforreviewloading = true;
    //     Promise.resolve().then(() => {
    //       if(["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx/xls/xlsm/xlsb file extension supported";
    //       return this.$nova.uploadFile(this.axios, file, {
    //         progressfunction: (e) => {
    //           try{
    //             let p = e.loaded*100/e.total;
    //             this.fileprocessingpercent[id] = p.toFixed(0);
    //             this.uploadcounter = this.uploadcounter + 0.01;
    //           }catch(e){
    //             console.log(e);
    //           }
    //         }
    //       })
    //     }).then(dt => {
    //       if(dt.data.status === "success" && dt.data.data.length > 0){
    //         this.fileprocessing[id] = 2;
    //         let data = {upload: dt.data.data[0]._id, project: this.id, checklist: id};
    //         return this.axios.post("/v2/financialclose/analysis/storeworkpaper", {data: [data]});
    //       }else throw new "Unable to upload file";
    //     }).then(dt => {
    //       if(dt.data.status === "success" && dt.data.data.length > 0){
    //         this.$store.commit("sbSuccess", "File uploaded");
    //         this.fileprocessing[id] = 0;
    //         this.sendforreviewdata.stage = 1;
    //         this.checklist[this.sendforreviewdata.__key] = {...this.sendforreviewdata};
    //         this.sendforreviewdialog = false;
    //         // this.refreshData();
    //       }else  throw new "error uploading file";
    //     }).catch(err => {
    //       this.fileprocessing[id] = 0;
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     }).finally(() => {
    //       this.files[id] = null;
    //       this.sendforreviewloading = false;
    //     })
    //   }
    // },
    // uploadFiles(id) {
    //   let totalSize = 0;
    //   let files = this.files[id];

    //   // Check if files is an array and has elements
    //   if (Array.isArray(files) && files.length > 0) {
    //     files.forEach((file, index) => {
    //       console.log(index);

    //       if ((file || {}).name && (this.fileprocessing[id] || 0) === 0) {
    //         totalSize += file.size;
    //       }
    //     });

    //     // Validate total file size (50 MB limit)
    //     if (totalSize > 50 * 1024 * 1024) {
    //       this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
    //       this.files[id] = null;
    //       return;
    //     }

    //     files.forEach((file, index) => {
    //       console.log(index);
    //       if ((file || {}).name && (this.fileprocessing[id] || 0) === 0) {
    //         this.fileprocessing[id] = 1;
    //         this.sendforreviewloading = true;

    //         if (["xlsx", "xls", "xlsm", "xlsb", "doc", "docx", "ppt", "pptx", "pdf"].indexOf(((file || {}).name || "").split(".").pop()) === -1) {
    //           this.fileprocessing[id] = 0;
    //           this.sendforreviewloading = false;
    //           this.$store.commit("sbError", "Only xlsb, doc, docx, ppt, pptx, pdf file extension supported");
    //           return;
    //         }

    //         let uploadPromise = this.$nova.uploadFile(this.axios, file, {
    //           progressfunction: (e) => {
    //             try {
    //               let p = (e.loaded * 100) / e.total;
    //               this.fileprocessingpercent[id] = p.toFixed(0);
    //               this.uploadcounter = this.uploadcounter + 0.01;
    //             } catch (e) {
    //               console.log(e);
    //             }
    //           },
    //         });

    //         uploadPromise
    //           .then((dt) => {
    //             console.log(dt,"DT");
    //             if (dt.data.status === "success" && dt.data.data.length > 0) {
    //                this.dataArray  =  dt.data.map(item => item.data[0]._id);
    //               this.fileprocessing[id] = 2;
    //               let data = { upload: this.dataArray, project: this.id, checklist: id };
    //               return this.axios.post("/v2/financialclose/analysis/storeworkpaper", { data: [data] });
    //             } else {
    //               throw new Error("Unable to upload file 123");
    //             }
    //           })
    //           .then((dt) => {
    //             if (dt.data.status === "success" && dt.data.data.length > 0) {
    //               this.$store.commit("sbSuccess", "File uploaded");
    //               this.fileprocessing[id] = 0;
    //               this.sendforreviewdata.stage = 1;
    //               this.checklist[this.sendforreviewdata.__key] = { ...this.sendforreviewdata };
    //               this.sendforreviewdialog = false;
    //             } else {
    //               throw new Error("Error uploading file");
    //             }
    //           })
    //           .catch((err) => {
    //             this.fileprocessing[id] = 0;
    //             this.$store.commit("sbError", err.message || err || "Unknown error!");
    //             console.log(err);
    //           })
    //           .finally(() => {
    //             this.files[id] = null;
    //             this.sendforreviewloading = false;
    //           });
    //       }
    //     });
    //   } else {
    //     // Handle the case when files is not an array or is empty
    //     this.$store.commit("sbError", "No files to upload");
    //   }
    // },

    async uploadFiles(id) {
     let totalSize = 0;
     let arrayPush = [];
     let files = this.files[id];
     this.sendforreviewloading = true
     // Check if files is an array and has elements
     if (Array.isArray(files) && files.length > 0) {
         files.forEach((file) => {
             if (file.name && (this.fileprocessing[id] || 0) === 0) {
                 totalSize += file.size;
             }
         });

         // Validate total file size (50 MB limit)
         if (totalSize > 50 * 1024 * 1024) {
             this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
             this.files[id] = null;
             return;
         }

         // Process each file in the array
         const uploadPromises = files.map(async (file) => {
             if (file.name && (this.fileprocessing[id] || 0) === 0) {
                 try {
                     // Upload file using Nova (assuming it returns a promise)
                     let dt = await this.$nova.uploadFile(this.axios, file, {
                         progressfunction: (e) => {
                             let p = (e.loaded * 100) / e.total;
                             this.fileprocessingpercent[id] = p.toFixed(0);
                             this.uploadcounter = this.uploadcounter + 0.01;
                         },
                     });

                     // Store the entire response object
                     arrayPush.push(dt);
                 } catch (err) {
                     // Handle errors during file upload
                     this.fileprocessing[id] = 0;
                     this.$store.commit("sbError", err.message || err || "Unknown error!");
                     console.error(err);
                 }
             }
         });

         // Wait for all upload promises to resolve
         await Promise.all(uploadPromises);

         // Check if any response has a status of "success"
         if (arrayPush.some((response) => response.data.status === "success")) {
             this.dataArray = arrayPush.flatMap((response) => response.data.data.map((item) => {
              return {"filename": item.uploadname,"file_id": item._id }
             }));
             this.fileprocessing[id] = 2;

             // Rest of your code...
             let data = {
                 upload: this.dataArray,
                 project: this.id,
                 checklist: id
             };

             // Continue with the post request
             try {
                 let response = await this.axios.post("/v2/financialclose/analysis/storeworkpaper", {
                     data: [data]
                 });

                 if (response.data.status === "success" && response.data.data.length > 0) {
                     this.$store.commit("sbSuccess", "File uploaded");
                     this.fileprocessing[id] = 0;
                     this.sendforreviewdata.stage = 1;
                     this.checklist[this.sendforreviewdata.__key] = {
                         ...this.sendforreviewdata
                     };
                     this.sendforreviewdialog = false;
                 } else {
                     throw new Error("Error uploading file");
                 }
             } catch (axiosError) {
                 this.fileprocessing[id] = 0;
                 this.$store.commit("sbError", axiosError.message || axiosError || "Unknown error!");
                 console.error(axiosError);
             } finally {
                 // Reset file and loading states
                 this.files = {};
                 this.refreshData();
                 this.sendforreviewloading = false;
             }
         } else {
             this.fileprocessing[id] = 0;
             this.$store.commit("sbError", "Unable to upload file");
         }
     } else {
         this.$store.commit("sbError", "No files to upload");
     }
 },
 
  async uploadFilesBulk(files, taskIds) {
    this.bulkfilesloading = 0;
    try {
        let totalSize = 0;
        let dataArrayList = [];
        if (Array.isArray(files) && files.length > 0) {
            files.forEach((file) => {
                if (file.name) {
                    totalSize += file.size;
                }
            });
            if (totalSize > 50 * 1024 * 1024) {
                this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
                return;
            }
            this.sendforreviewloadingbulk = true;
            const uploadPromises = files.map(async (file) => {
                if (file.name) {
                    try {
                        let response = await this.$nova.uploadFile(this.axios, file, {});
                        dataArrayList.push(response);
                    } catch (uploadError) {
                        this.$store.commit("sbError", uploadError.message || "Error uploading file");
                        console.error(uploadError);
                    }
                }
            });
            this.bulkfilesloading = 0;
            await Promise.all(uploadPromises);
             if (dataArrayList.some((response) => response.data.status === "success")) {
             this.dataArray = dataArrayList.flatMap((item)=>item.data.data.flatMap((item) => {
              return { "filename": item.uploadname, "file_id": item._id }
            }));
            for (let index = 0; index < taskIds.length; index++) {
              let data = {
                  upload: this.dataArray,
                  project: this.id,
                  checklist: taskIds[index],
                  removelocalfile: taskIds.length - index === 1? true: false,
              };
              try {
              this.bulkfilesloading = 0;
                let response = await this.axios.post("/v2/financialclose/analysis/storeworkpaper", { data: [data] });
                if (response.data.status === "success" && response.data.data.length > 0) {
                    this.$store.commit("sbSuccess", "File uploaded");
                    this.bulkfilesloading = 1;
                    if(index === (taskIds.length-1)){
                      this.sendForReviewDialogbulk = false;
                      this.refreshData();
                    }
                } else {
                    throw new Error("Error Uploading File")
                }
            }catch (axiosError) {
                this.filesbulk = {};
                this.$store.commit("sbError", axiosError.message || "Unknown error!");
              }
            }
            this.sendforreviewloadingbulk = false;
            } else {
                this.$store.commit("sbError", "Unable to upload file 123");
            }
        } else {
            this.$store.commit("sbError", "No files to upload");
        }
    } catch (error) {
        console.error(error);
    } finally {
        this.filesbulk = {};
        this.bulkfilesloading = 1;
    }
  },

   
  opensendForReviewDialogbulk(taskids){
    this.sendforreviewdialogbulk = true;
    console.log(taskids);
  },


    downloadExcel() {
      let dt = [
        [undefined],
        [
          undefined, 
          {v: this.$store.state.clientlist[0].name || "", s: {font: {bold: true, sz: 20}}}, 
        ],
        [
          undefined, 
          {v: this.metadata.description, s: {font: {bold: true, sz: 14}}}, 
        ],
        [
          undefined, 
          {v: this.$nova.formatDate(this.metadata.from) + " - " + this.$nova.formatDate(this.metadata.from), s: {font: {bold: true, sz: 14}}}, 
        ],
        [
          undefined
        ],
        [
          undefined,
          {v: "Reference", s: {font: {bold: true}}},
          {v: "Task Description", s: {font: {bold: true}}},
          {v: "Category", s: {font: {bold: true}}},
          {v: "Sub Category", s: {font: {bold: true}}},
           { v: "Location", s: { font: { bold: true } } },
          {v: "Assertions", s: {font: {bold: true}}},
          {v: "Due Date", s: {font: {bold: true}}},
          {v: "Amount as per Trial Balance", s: {font: {bold: true}}},
          {v: "Amount as per Schedule", s: {font: {bold: true}}},
          {v: "Difference", s: {font: {bold: true}}},
          {v: "Purpose", s: {font: {bold: true}}},
          {v: "Instruction", s: {font: {bold: true}}},
          {v: "Preparer", s: {font: {bold: true}}},
          {v: "Approver", s: {font: {bold: true}}},
          {v: "Status", s: {font: {bold: true}}},
        ]];
        for (const v of this.checklist) {
          dt.push([
            undefined,
            v.ref, 
            v.description, 
            v.category, 
            v.subcategory, 
            v.location,
            (v.assertions || []).join(", "), 
            {v: (v.subcategory)?this.$nova.formatDate(v.duedate):'', z: 'dd-mmm-yyyy', t: 'd'},
            {v: ((v.__tbtotals || {}).total || 0).toFixed(0), z: '#,##', t: 'n'},
            {v: (Number((v.result || {}).value?.total_value) || 0).toFixed(0), z: '#,##', t: 'n'},
            {v: (((v.__tbtotals || {}).total || 0) - (Number((v.result || {}).value?.total_value) || 0)).toFixed(0), z: '#,##', t: 'n'},
            v.purpose, 
            v.instruction, 
            this.getUserList(v.preparer),
            this.getUserList(v.approver),
            this.getStageText(v.stage)
          ])
        }
        // console.log(dt);

        // Extract Data (create a workbook object from the table)
        let workbook = styledxlsx.utils.book_new();
        let worksheet = styledxlsx.utils.aoa_to_sheet(dt);
        styledxlsx.utils.book_append_sheet(workbook, worksheet, "Tasklist");
        styledxlsx.writeFile(workbook, this.metadata.description + " - Tasklist.xlsx");

        // console.log(xlsx);
    },
    getUserList(x){
      let rdt = [];
      for (const i of x) {
        rdt.push(this.userlist[i]?.displayname || "")
      }
      return rdt.join(", ")
    },
    getStageText(x){
      if(x === 0) return "Pending Submission";
      else if(x === 1) return "Processing";
      else if(x === 2) return "Pending Approval";
      else if(x === 3) return "Closed Task";
      else return "Unknown";
    },

    formatChecklistData: function(){
      let dta = [];
      let catdata = {};
      for (let i = 0; i < (this.checklist || []).length; i++) {
        const el = this.checklist[i];
        el.__key = i;
        let cat = el.category;
        el.location = el.location || [];
        if((el.location).length>0){
          el.locationItem=(this.formdata.locations.filter(obj=> obj["_id"]===el.location))|| [{"name":""}];
          if(el.locationItem.length>0){
            el.locationName = el.locationItem[0].name || "";
          }
          else el.locationName = "";
          
        }
        
        if(this.stagefilter.indexOf(el.stage) > -1){
          catdata[cat] = catdata[cat] || [];
          catdata[cat].push(el);
        }
      }
      for (const k in catdata) {
        if (Object.hasOwnProperty.call(catdata, k)) {
          const el = catdata[k];
          dta.push({type: 'category', name: k});
          dta = [...dta, ...el];
        }
      }
      this.formattedchecklistdata =  dta;
      this.filterformated()
    },
    nameReturn(ids) {
      let arrayTem = [];
      for (const id of ids) {
        arrayTem.push(this.userlist[id])
      }
      return arrayTem;
    },
    filterloaction(id,item){
      return item.filter(obj => obj.location && obj.location.includes(id));
    },
    getTaskDeatils(id) {
      const foundItem = this.formattedchecklistdata.find(item => item._id === id);
      return foundItem ? foundItem : "ID not found";
    },
  },
  computed: {
    filteredItems() {
        if (this.searchcustomfilter === "" || this.searchcustomfilter === undefined) {
            return this.tb.filter(item => {
              if (Object.keys(item.location_detail[0] || {}).includes('_id')) {
                if (item.location_detail[0]._id === this.addeditdata.location) {
                  return item;
                }
              }
        }
        );
        } else {
            return this.tb.filter(item =>{
              if(Object.keys(item.location_detail[0] || {}).includes('_id')){
                  if(item.location_detail[0]._id===this.addeditdata.location){
                    return Object.values(item).some(val =>
                        val !== null && val !== undefined &&
                        val.toString().toLowerCase().includes(this.searchcustomfilter.toLowerCase())
                    )
                  }
                }
              }
            );
        }
    },
    finaluserlist() {
      let nl = [];
      for (const i of this.metadata.managerlist) {
        let ud = { ...this.userlist[i] };
        ud.type = "manager";
        ud._id = this.userlist[i]._id;
        ud.name = this.userlist[i].name;
        nl.push(ud);
      }
      for (const i of this.metadata.userlist) {
        let ud = { ...this.userlist[i] };
        ud.type = "general";
        ud._id = this.userlist[i]._id;
        ud.name = this.userlist[i].name;
        nl.push(ud);
      }
      return nl;
    },
    filteredUserList() {
      return this.filterListitems.userlists.filter(user => {
        return this.userSearch === '' || (this.userlist[user] && this.userlist[user].name.toLowerCase().includes(this.userSearch.toLowerCase()));
      });
    },
    filteredLocationList() {
      return this.filterListitems.locations.filter(location => {
        return this.locationSearch === '' || (location.toLowerCase().includes(this.locationSearch.toLowerCase()));
      });
      }
  },
  watch: {
    "addeditproject.from": function(){
      this.generateDescription();
    },
    "addeditproject.to": function(){
      this.generateDescription();
    },
    "stagefilter": function(){
      this.formatChecklistData();
    },
    "addeditdata.location":function(){
      this.addeditdata.location = this.addeditdata.location || [];
      this.eligibleusers.preparer = [];
      this.eligibleusers.approver = [];
      if(this.addeditdata.location.length>0){
        let locationObject = this.formdata.locations.filter(obj => obj['_id'] === this.addeditdata.location)
        let filter;
        for (const approver in locationObject[0].approver){
           filter = this.formdata.users.filter(obj=>obj['_id'] === locationObject[0].approver[approver]);
           this.eligibleusers.approver.push(filter[0])
        }
        for (const preparer in locationObject[0].preparer) {
          filter = this.formdata.users.filter(obj => obj['_id'] === locationObject[0].preparer[preparer]);
          this.eligibleusers.preparer.push(filter[0])
        }
        // this.eligibleusers.preparer = this.formdata.users;
        // this.eligibleusers.approver = this.formdata.users;
      this.isLocationSelected = true;
      }
      else{
        this.isLocationSelected = false;
      }
    },    
  }
}
</script>

<style scoped>
  .filterlists{
    margin-left: 280px !important;
  }
  .max-width-div {
    max-width: 700px; /* Adjust the value as needed */
  }
</style>
